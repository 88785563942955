// @ts-check

import { useMemo, useState } from 'react'
import Toaster from '../components/shared/toaster.component'

/**
 * Tracks an ID that can be used to refresh a `useEffect` (as a dependency) when
 * triggered by calling the `refresh` function.
 *
 * Also provides a `Toaster` implementation that can be rendered to notify users
 * with the `message` parameter passed to `refresh`.
 *
 * **Note:** this is not at all an ideal solution for managing state across
 * components, but restructuring the app state to account for this would be far
 * too burdensome. This is at least an abstraction of the existing pattern to
 * make it easier to maintain.
 */
export const useRefresher = () => {
	const [id, setId] = useState(0)
	const [toastMessage, setToastMessage] = useState(
		/** @type {string | null} */ (null),
	)

	return useMemo(
		() =>
			/** @type {const} */ ({
				id,
				/** @param {string} [message] */
				refresh: (message) => {
					setId((previousId) => previousId + 1)
					if (message) {
						setToastMessage(message)
						setTimeout(() => {
							setToastMessage(null)
						}, 5000)
					}
				},
				/**
				 * @param {object} props
				 * @param {string | undefined} [props.header]
				 */
				Toaster: ({ header }) => (
					<Toaster message={toastMessage} header={header} />
				),
			}),
		[id, toastMessage],
	)
}

/** @typedef {ReturnType<typeof useRefresher>} Refresher */
