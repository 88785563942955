// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'
import { EpicorBaseService } from './base.service'

/** @import { PaymentMethod } from "@elevate/shared/payments" */

/**
 * @extends {EpicorBaseService<'invoices', Api.Invoice, Api.InvoiceLine>}
 */
class EpicorInvoiceService extends EpicorBaseService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		super('invoices', http)
		this.#http = http
	}

	#http

	/**
	 * @param {Id<'customers'>} customerId
	 * @param {Id<'invoices'>} invoiceNum
	 * @param {object} body
	 * @param {string} body.currency
	 * @param {number} body.paymentAmount
	 * @param {string} body.paymentIntentId
	 * @param {PaymentMethod} body.paymentMethod
	 * @returns {AxiosReturn<{
	 *   clientSecret: string
	 *   paymentIntentId: string
	 * }>}
	 */
	paymentIntent(customerId, invoiceNum, body) {
		return this.#http.post(
			`/api/customers/${customerId}/invoices/${invoiceNum}/payments/preauth`,
			body,
		)
	}

	/**
	 * @param {Id<'customers'>} customerId
	 * @param {Id<'invoices'>} invoiceNum
	 * @param {object} body
	 * @param {string} body.paymentIntentId
	 * @param {string} body.response
	 * @param {boolean} body.success
	 * @param {number} body.totalCharge
	 * @param {string} body.currency
	 */
	paymentCreate(customerId, invoiceNum, body) {
		return this.#http.post(
			`/api/customers/${customerId}/invoices/${invoiceNum}/payments`,
			body,
		)
	}
}

export function useEpicorInvoiceService() {
	const http = useHttp()
	return useMemo(() => new EpicorInvoiceService(http), [http])
}
