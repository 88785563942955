// @ts-check

import { differenceInDays } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	Accordion,
	Alert,
	Badge,
	Button,
	Card,
	Col,
	Container,
	Row,
	Spinner,
} from 'react-bootstrap'
import { useAuthenticated } from 'hooks/useAuth'
import { useSettings } from 'hooks/useSettings'
import {
	useEpicorCustomerService,
	useEpicorWidgetService,
} from 'services/epicor'
import {
	formatCurrency,
	formatDate,
	downloadFile,
	getErrorMessage,
} from 'components/utilities'
import OrderForm from 'components/orders/order.form.component'
import CustomerAddress from 'components/shared/customer.address.component'

export default function Home() {
	const { currentCompany } = useAuthenticated()

	return currentCompany.isClient ? (
		<ClientHome />
	) : (
		<CustomerHome customer={currentCompany} />
	)
}

function ClientHome() {
	const widgets = useWidgets({ isClient: true })

	return (
		<>
			<p className="d-none d-md-block mx-auto mb-0" style={{ maxWidth: 600 }}>
				Welcome to ElevateERP—your gateway to seamless customer connectivity!
				Empower your customers with direct access to your data, reducing manual
				effort, enhancing accuracy, and delivering a superior self-service
				experience. Elevate efficiency, boost productivity, and transform the
				way you do business today!
			</p>
			<div className="d-flex flex-wrap justify-content-center align-items-center">
				<OpenOrdersWidget {...widgets} />
				<OpenInvoicesWidget {...widgets} />
				<ERPVersionWidget {...widgets} />
			</div>
		</>
	)
}

/**
 * @param {object} props
 * @param {AccountCustomer} props.customer
 */
function CustomerHome({ customer }) {
	const { settings } = useSettings()
	const navigate = useNavigate()
	const widgets = useWidgets({
		isClient: false,
		customerId: customer.accountId,
	})
	const EpicorCustomerService = useEpicorCustomerService()
	const [showOrderForm, setShowOrderForm] = useState(false)

	const [currentCustomer, setCurrentCustomer] = useState(
		/** @type {Api.CustomerDetails | null} */ (null),
	)
	const [customerErrorMessage, setCustomerErrorMessage] = useState(
		/** @type {string | null} */ (null),
	)

	useEffect(() => {
		async function fetchCustomerDetails() {
			try {
				const response = await EpicorCustomerService.getDetails(
					customer.accountId,
				)
				setCurrentCustomer(response.data)
				setCustomerErrorMessage(null)
			} catch (error) {
				setCustomerErrorMessage(getErrorMessage(error))
				setCurrentCustomer(null)
			}
		}
		fetchCustomerDetails()
	}, [EpicorCustomerService, customer.accountId])

	const [downloadErrorMessage, setDownloadErrorMessage] = useState(
		/** @type {string | null} */ (null),
	)

	const downloadStatement = async () => {
		try {
			const response = await EpicorCustomerService.getStatement(
				customer.accountId,
			)
			downloadFile(response)
			setDownloadErrorMessage(null)
		} catch (error) {
			setDownloadErrorMessage(getErrorMessage(error))
		}
	}

	const downloadAttachments = async () => {
		setDownloadErrorMessage(null)

		try {
			const response = await EpicorCustomerService.getAttachments(
				customer.accountId,
			)
			downloadFile(response)
		} catch (error) {
			setDownloadErrorMessage(getErrorMessage(error))
		}
	}

	return (
		<>
			<OrderForm
				show={showOrderForm}
				onClose={() => setShowOrderForm(false)}
				onOperationCompleted={() => navigate('/orders')}
			/>
			<Accordion defaultActiveKey={['CustomerInfo', 'Dashboard']} alwaysOpen>
				<Accordion.Item eventKey="CustomerInfo">
					<Accordion.Header>{`${customer.custName} Details`}</Accordion.Header>
					<Accordion.Body>
						<Container>
							<Row className="align-items-stretch gy-3">
								<Col>
									<Card className="bg-grey border-0 d-flex flex-column h-100">
										<Card.Body>
											<Card.Text as="div">
												{downloadErrorMessage && (
													<Alert variant="danger">{downloadErrorMessage}</Alert>
												)}
												<div className="d-flex flex-column gap-3">
													<Button
														variant="success"
														className="w-100"
														onClick={() => {
															setShowOrderForm(true)
														}}
													>
														Create New Order
													</Button>
													<Button className="w-100" onClick={downloadStatement}>
														Download Statement
													</Button>
													{!settings.customerDisableAttach && (
														<Button
															className="w-100"
															onClick={downloadAttachments}
														>
															Download Documents
														</Button>
													)}
												</div>
											</Card.Text>
										</Card.Body>
									</Card>
								</Col>
								<Col lg={6} xl={4}>
									<Card className="bg-grey border-0 d-flex flex-column h-100">
										<Card.Body>
											<Card.Text as="div">
												{customerErrorMessage ? (
													customerErrorMessage
												) : !currentCustomer ? (
													<Spinner />
												) : (
													<CustomerAddress customer={currentCustomer} />
												)}
											</Card.Text>
										</Card.Body>
									</Card>
								</Col>
								<Col lg={6} xl={4}>
									<Card className="bg-grey border-0 d-flex flex-column h-100">
										<Card.Body>
											<Card.Text as="div">
												{customerErrorMessage ? (
													customerErrorMessage
												) : !currentCustomer ? (
													<Spinner />
												) : (
													<CustomerInfo currentCustomer={currentCustomer} />
												)}
											</Card.Text>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Container>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="Dashboard">
					<Accordion.Header>Dashboard</Accordion.Header>
					<Accordion.Body>
						<div className="d-flex flex-wrap justify-content-center align-items-stretch gap-3 w-100">
							{!settings.orderDisableWidget && (
								<OpenOrdersWidget {...widgets} />
							)}
							{!settings.invoiceDisableWidget && (
								<OpenInvoicesWidget {...widgets} />
							)}
							{!settings.shipmentDisableWidget && (
								<LastShipmentWidget {...widgets} />
							)}
						</div>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</>
	)
}

/**
 * @param {object} props
 * @param {Api.CustomerDetails} props.currentCustomer
 */
function CustomerInfo({ currentCustomer }) {
	return (
		<>
			<ul className="info-list list-unstyled mb-0">
				<li>
					<strong>Terms: </strong>
					{currentCustomer.termsDescription}
				</li>
				<li>
					<strong>Credit Status: </strong>
					{currentCustomer.creditHold ? (
						<Badge bg="warning" text="dark">
							On Hold
						</Badge>
					) : (
						<Badge bg="success">OK</Badge>
					)}
				</li>
				<li>
					<strong>Credit Limit: </strong>
					{formatCurrency(
						currentCustomer.creditLimit,
						currentCustomer.currencyCurrencyID,
					)}
				</li>
				<li>
					<strong>Open Credit: </strong>
					{formatCurrency(
						currentCustomer.totOpenCredit,
						currentCustomer.currencyCurrencyID,
					)}
				</li>
			</ul>
			<hr />
			<ul className="info-list list-unstyled mb-0">
				<li>
					<strong>Rep: </strong>
					{currentCustomer.salesRepName}
				</li>
				{currentCustomer.salesRepOfficePhoneNum && (
					<li>
						<strong>Rep Phone: </strong>
						{currentCustomer.salesRepOfficePhoneNum}
					</li>
				)}
				{currentCustomer.salesRepEMailAddress && (
					<li>
						<strong>Rep Email: </strong>
						{currentCustomer.salesRepEMailAddress}
					</li>
				)}
			</ul>
		</>
	)
}

/**
 * @param {{
 * 	isClient: true
 * 	customerId?: undefined
 * } | {
 * 	isClient: false
 * 	customerId: Id<'customers'>
 * }} props
 */
function useWidgets(props) {
	const EpicorWidgetService = useEpicorWidgetService()

	const [errorMessage, setErrorMessage] = useState(
		/** @type {string | null} */ (null),
	)
	const [queryResult, setQueryResult] = useState(
		/** @type {Api.QueryWidget | null} */ (null),
	)
	const [isLoading, setIsLoading] = useState(true)

	const fetchWidgets = useCallback(async () => {
		setIsLoading(true)
		try {
			const response = props.isClient
				? await EpicorWidgetService.getClientWidget('query')
				: await EpicorWidgetService.getCustomerWidget('query', props.customerId)

			if (response.data) {
				setQueryResult(response.data)
				setErrorMessage(null)
			} else {
				setErrorMessage('No Widgets returned!')
			}
		} catch (error) {
			setErrorMessage(getErrorMessage(error))
		} finally {
			setIsLoading(false)
		}
	}, [EpicorWidgetService, props.customerId, props.isClient])

	useEffect(() => {
		fetchWidgets()
	}, [fetchWidgets])

	return /** @satisfies {WidgetProps} */ ({
		errorMessage,
		isLoading,
		queryResult,
	})
}

/**
 * @param {WidgetProps} props
 */
function OpenOrdersWidget({ isLoading, errorMessage, queryResult }) {
	const { currentCompany } = useAuthenticated()
	return (
		<Card className="card-widget">
			<Card.Body>
				<Card.Title>Open Orders</Card.Title>
				{isLoading ? (
					<Spinner />
				) : errorMessage ? (
					<Alert variant="danger">{errorMessage}</Alert>
				) : (
					<>
						<h2>
							<Badge bg="dark">{queryResult?.calculatedOrderCount}</Badge>
						</h2>
						<h2>
							{formatCurrency(queryResult?.calculatedOrderAmt ?? 0, {
								withoutCents: true,
							})}
						</h2>
					</>
				)}
			</Card.Body>
			{!currentCompany.isClient && (
				<a href="/orders" className="btn btn-primary">
					Go
				</a>
			)}
		</Card>
	)
}

/**
 * @param {WidgetProps} props
 */
function OpenInvoicesWidget({ isLoading, errorMessage, queryResult }) {
	const { currentCompany } = useAuthenticated()
	return (
		<Card className="card-widget">
			<Card.Body>
				<Card.Title>Open Invoices</Card.Title>
				{isLoading ? (
					<Spinner />
				) : errorMessage ? (
					<Alert variant="danger">{errorMessage}</Alert>
				) : (
					<>
						<h2>
							<Badge bg="dark">{queryResult?.calculatedInvoiceCount}</Badge>
						</h2>
						<h2>
							{formatCurrency(queryResult?.calculatedInvoiceAmt ?? 0, {
								withoutCents: true,
							})}
						</h2>
					</>
				)}
			</Card.Body>
			{!currentCompany.isClient && (
				<a href="/invoices" className="btn btn-primary">
					Go
				</a>
			)}
		</Card>
	)
}

/**
 * @param {WidgetProps} props
 */
function ERPVersionWidget({ isLoading, errorMessage, queryResult }) {
	const { settings } = useSettings()

	const versionDaysOld = queryResult?.calculatedVersionInstall
		? differenceInDays(
				new Date(queryResult.calculatedVersionInstall),
				new Date(),
			)
		: null
	const versionDaysMax = Number(settings.versionDays || 90)

	return (
		<Card className="card-widget">
			<Card.Body>
				<Card.Title>ERP Version</Card.Title>
				{isLoading ? (
					<Spinner />
				) : errorMessage ? (
					<Alert variant="danger">{errorMessage}</Alert>
				) : (
					<>
						<h2>
							<Badge bg="dark">{queryResult?.calculatedVersion01}</Badge>
						</h2>
						{versionDaysOld !== null && (
							<h3>
								<Badge
									bg={versionDaysOld > versionDaysMax ? 'danger' : 'success'}
								>
									{new Intl.RelativeTimeFormat('en', { style: 'short' }).format(
										versionDaysOld,
										'day',
									)}
								</Badge>
							</h3>
						)}
					</>
				)}
			</Card.Body>
		</Card>
	)
}

/**
 * @param {WidgetProps} props
 */
function LastShipmentWidget({ isLoading, errorMessage, queryResult }) {
	return (
		<Card className="card-widget">
			<Card.Body>
				<Card.Title>Last Shipment</Card.Title>
				{isLoading ? (
					<Spinner />
				) : errorMessage ? (
					<Alert variant="danger">{errorMessage}</Alert>
				) : (
					<>
						{queryResult?.calculatedLastShipment && (
							<h2>{formatDate(queryResult?.calculatedLastShipment)}</h2>
						)}
						<p>{queryResult?.shipViaDescription}</p>
						<p>
							{
								queryResult?.shipHeadTrackingNumber === ''
									? 'No Tracking Provided'
									: queryResult?.shipHeadTrackingNumber //TODO: Check if BAQ has these fields
							}
						</p>
					</>
				)}
			</Card.Body>
			<Button href="/shipments" className="btn btn-primary">
				Go
			</Button>
		</Card>
	)
}

/**
 * @typedef {{
 * 	errorMessage: string | null
 * 	isLoading: boolean
 * 	queryResult: Api.QueryWidget | null
 * }} WidgetProps
 */
