import { useState, useCallback } from 'react'
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import useAuth from 'hooks/useAuth'
import { useEpicorQueryService } from 'services/epicor'
import _ from 'lodash'

function usePermissions() {
	const [allowUpdate, setAllowUpdate] = useState(false)
	const [allowAddNew, setAllowAddNew] = useState(false)
	const setPermissions = useCallback(({ allowUpdate, allowAddNew }) => {
		setAllowUpdate(allowUpdate)
		setAllowAddNew(allowUpdate ? allowAddNew : false)
	}, [])

	return {
		allowUpdate,
		allowAddNew,
		setPermissions,
	}
}

const NewQuery = ({ onSubmitted }) => {
	const { currentCompany } = useAuth()
	const EpicorQueryService = useEpicorQueryService()
	const [error, setError] = useState(null)
	const [warning, setWarning] = useState(null)
	const [queryID, setQueryID] = useState('')
	const [queryCustNumField, setQueryCustNumField] = useState('')
	const [queryName, setQueryName] = useState('')
	const { allowUpdate, allowAddNew, setPermissions } = usePermissions()
	const [queryFields, setQueryFields] = useState([])
	const [savingQuery, setSavingQuery] = useState(false)
	const data = {
		queryID,
		queryName,
		queryCustNumField,
		allowUpdate,
		allowAddNew,
	}

	const submit = async (event) => {
		event.preventDefault()
		if (queryFields.count === 0) return
		if (queryCustNumField === '') setQueryCustNumField(queryFields[0])
		setSavingQuery(true)
		try {
			const response = await EpicorQueryService.create(
				currentCompany.environmentId,
				currentCompany.companyId,
				data,
			)
			const { error } = response.data
			if (error) {
				setError(JSON.stringify(error))
			} else {
				onSubmitted(response.data)
			}
		} catch (error) {
			setError(error.message)
		} finally {
			setSavingQuery(false)
		}
	}

	const QueryFieldOptions = () => {
		return queryFields.map((queryField) => (
			<option key={queryField.alias} value={queryField.alias}>
				{queryField.alias}
			</option>
		))
	}

	const fetchFieldsList = (baqID) => {
		setWarning(null)
		setError(null)
		if (baqID.length < 3) return
		EpicorQueryService.fieldsList(
			currentCompany.environmentId,
			currentCompany.companyId,
			baqID,
		)
			.then((response) => {
				setQueryFields(response.data)
			})
			.catch((error) => {
				setQueryFields([])
				if (error.response.status === 404) setWarning('Query Not Found')
				else console.log(error.response.data.message)
			})
			.finally()
	}

	const requiredFieldsCompleted = !_.isNull(queryCustNumField)

	return (
		<Form onSubmit={submit}>
			<Row>
				<Col className="d-flex flex-column">
					{!_.isNull(error) ? (
						<Alert dismissible variant="danger" onClose={() => setError(null)}>
							{error}
						</Alert>
					) : null}
					{!_.isNull(warning) ? (
						<Alert
							dismissible
							variant="warning"
							onClose={() => setWarning(null)}
						>
							{warning}
						</Alert>
					) : null}
					<Form.Group controlId="form.queryID">
						<Form.Label>Query ID</Form.Label>
						<Form.Control
							name="queryID"
							placeholder="BAQ ID"
							value={queryID || ''}
							onChange={(e) => setQueryID(e.target.value)}
							onBlur={(e) => fetchFieldsList(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group controlId="form.queryCustNumField">
						<Form.Label>CustNum Field (used to link to Customer)</Form.Label>
						<Form.Select
							name="queryCustNumField"
							value={queryCustNumField || ''}
							onChange={(e) => setQueryCustNumField(e.target.value)}
							required
						>
							<QueryFieldOptions />
						</Form.Select>
					</Form.Group>
					<Form.Group controlId="form.queryName">
						<Form.Label>Query Name</Form.Label>
						<Form.Control
							name="queryName"
							placeholder="Give the Query a Customer-Friendly Description"
							value={queryName}
							onChange={(e) => setQueryName(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group controlId="form.allowUpdate">
						<Form.Check
							name="allowUpdate"
							label="Updateable"
							checked={allowUpdate}
							onChange={(event) =>
								setPermissions({
									allowUpdate: event.target.checked,
									allowAddNew,
								})
							}
						/>
					</Form.Group>
					<Form.Group controlId="form.allowAddNew">
						<Form.Check
							name="allowAddNew"
							label="Allow Add New"
							checked={allowAddNew}
							onChange={(event) =>
								setPermissions({
									allowUpdate,
									allowAddNew: event.target.checked,
								})
							}
							disabled={!allowUpdate}
						/>
					</Form.Group>
					<p />
					<Button
						variant="primary mt-auto"
						type="submit"
						disabled={!requiredFieldsCompleted || savingQuery}
					>
						{savingQuery ? (
							<Spinner animation="border" role="status">
								<span className="visually-hidden">
									Saving Query, please wait...
								</span>
							</Spinner>
						) : (
							'Save Query'
						)}
					</Button>
					{!requiredFieldsCompleted ? (
						<small className="text-center text-grey">
							Please, make sure to complete all the required elements
						</small>
					) : null}
				</Col>
			</Row>
		</Form>
	)
}

export default NewQuery
