import { useMemo, useState, Fragment } from 'react'
import { useSortBy, useTable } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import { getColumnAlignment } from 'components/utilities'
import { accessors } from './rma.table.accessors.component'
import RecordLines from '../shared/lines.list.component'
import { linesAccessors } from './lines.table.accessors.component'
import TableHead from '../shared/table.header.component'
import _ from 'lodash'

const RMAsTable = ({ rmas, onPrintRMA }) => {
	const [expandedRow, setExpandedRow] = useState(
		/** @type {Id<'rmas'> | null} */ (null),
	)
	const columns = useMemo(() => accessors(), [])
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data: rmas },
		useSortBy,
	)

	const TableBody = () => {
		/**
		 * @param {Id<'rmas'>} rMANum
		 * @param {import('react-table').Cell} cell
		 */
		const toggleCollapse = (rMANum, cell) => () => {
			if (cell.column.id === '⚡') return
			setExpandedRow((prevState) => (rMANum === prevState ? null : rMANum))
		}
		return (
			<tbody>
				{rows.map((row) => {
					prepareRow(row)
					const { key: rowKey, ...rowProps } = row.getRowProps()
					const isExpanded = expandedRow === row.original.rMANum
					return (
						<Fragment key={rowKey}>
							<tr
								className={isExpanded ? 'table-active' : undefined}
								{...rowProps}
							>
								{row.cells.map((cell) => {
									const { key: cellKey, ...cellProps } = cell.getCellProps()
									return (
										<td
											key={cellKey}
											{...cellProps}
											onClick={toggleCollapse(row.original.rMANum, cell)}
											className={getColumnAlignment(cell.column.id)}
										>
											{cell.render('Cell', { onPrintRMA })}
										</td>
									)
								})}
							</tr>
							{isExpanded && (
								<tr className="table-active">
									<td colSpan={row.cells.length}>
										<RecordLines
											record={row.original.rMANum}
											type="rma"
											linesAccessors={linesAccessors}
										/>
									</td>
								</tr>
							)}
						</Fragment>
					)
				})}
			</tbody>
		)
	}

	return !_.isEmpty(rmas) ? (
		<BTable responsive hover {...getTableProps()}>
			<TableHead headerGroups={headerGroups} />
			<TableBody />
		</BTable>
	) : (
		<p>The current customer does not have any RMAs.</p>
	)
}

export default RMAsTable
