// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'

class EpicorCustomerService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		this.#http = http
	}

	#http

	/**
	 * @param {Id<'customers'>} customerId
	 * @returns {AxiosReturn<Api.CustomerInfo>}
	 */
	getInfo(customerId) {
		return this.#http.get(`/api/customers/${customerId}`)
	}

	/**
	 * @param {Id<'customers'>} customerId
	 * @returns {AxiosReturn<Api.CustomerDetails>}
	 */
	getDetails(customerId) {
		return this.#http.get(`/api/customers/${customerId}/details`)
	}

	/**
	 * @param {Id<'customers'>} customerId
	 * @returns {AxiosReturn<{ readonly value: readonly Api.Contact[] }>}
	 */
	getContacts(customerId) {
		return this.#http.get(`/api/customers/${customerId}/contacts`)
	}

	/**
	 * @param {Id<'customers'>} customerId
	 */
	getStatement(customerId) {
		return this.#http.get(`/api/customers/${customerId}/statement`, {
			responseType: 'blob',
		})
	}

	/**
	 * @param {Id<'customers'>} customerId
	 */
	getAttachments(customerId) {
		return this.#http.get(`/api/customers/${customerId}/attachments`, {
			responseType: 'blob',
		})
	}
}

export function useEpicorCustomerService() {
	const http = useHttp()
	return useMemo(() => new EpicorCustomerService(http), [http])
}
