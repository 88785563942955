// @ts-check

import { Col, Container, Row } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import ImpersonateModal from './impersonate/impersonate.modal.component'
import ImpersonatedUserTopBar from './impersonate/impersonated.user.top.bar.component'
import SideBar from './sidebar/sidebar.component'
import Footer from './sidebar/footer.component'
import { SiteHeader } from './header/site-header.component'
import useAuth from 'hooks/useAuth'
import { useImpersonation } from 'hooks/useImpersonation'

const MainContent = () => {
	const { currentCompany } = useAuth()
	const { isImpersonationModalOpen, closeImpersonationModal } =
		useImpersonation()

	return (
		<Container fluid className="d-flex flex-column flex-grow-1">
			{currentCompany?.isClient && (
				<ImpersonateModal
					show={isImpersonationModalOpen}
					onSelect={() => {
						closeImpersonationModal()
					}}
					onClose={() => {
						closeImpersonationModal()
					}}
				/>
			)}
			<Row className="py-2">
				<Col className="d-flex flex-column gap-2">
					<Outlet />
				</Col>
			</Row>
		</Container>
	)
}

const Main = () => {
	const { impersonatedCustomer } = useImpersonation()
	return (
		<Container fluid className="h-100 d-flex flex-column">
			{impersonatedCustomer && (
				<Row>
					<ImpersonatedUserTopBar impersonatedCustomer={impersonatedCustomer} />
				</Row>
			)}
			<Row className="h-100 overflow-y-auto d-flex flex-column flex-md-row flex-md-nowrap">
				<Col className="sidenav h-md-100 overflow-y-auto" md="auto">
					<SideBar />
				</Col>
				<Col className="h-100 overflow-y-auto d-flex flex-column">
					<SiteHeader />
					<MainContent />
					<Footer />
				</Col>
			</Row>
		</Container>
	)
}
export default Main
