// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'
import { useSession } from 'hooks/useSession'

class SettingsService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 * @param {Account} currentCompany
	 */
	constructor(http, currentCompany) {
		this.#http = http
		this.#currentCompany = currentCompany
	}

	#http
	#currentCompany

	/**
	 * @returns {AxiosReturn<readonly Api.Setting[]>}
	 */
	findAll() {
		const { companyId, environmentId } = this.#currentCompany
		return this.#http.get(
			`/api/environments/${environmentId}/company/${companyId}/settings`,
		)
	}

	/**
	 * @param {readonly Api.Setting[]} data
	 * @returns {AxiosReturn<readonly Api.Setting[]>}
	 */
	update(data) {
		const { companyId, environmentId } = this.#currentCompany
		return this.#http.put(
			`/api/environments/${environmentId}/company/${companyId}/settings`,
			data,
		)
	}
}

export function useSettingsService() {
	const http = useHttp()
	const { currentCompany } = useSession()
	if (!currentCompany) {
		throw new Error(
			'Settings service must have currentCompany loaded in the session.',
		)
	}

	return useMemo(
		() => new SettingsService(http, currentCompany),
		[currentCompany, http],
	)
}
