// @ts-check

import { createContext, useContext } from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { LoadingPage } from 'components/shared/loading/loading.page.component'
import { useImpersonation } from './useImpersonation'
import { useSession } from './useSession'

/** @import { ReactNode } from 'react' */

const AuthContext = createContext(
	/** @type {Context.Auth | undefined} */ (undefined),
)

/**
 * @param {object} props
 * @param {ReactNode} props.children
 */
export function AuthProvider({ children }) {
	const {
		currentCompany,
		user,
		loading,
		setCurrentCompany,
		setUser,
		clearSession,
	} = useSession()

	/** @satisfies {Context.Auth['signin']} */
	const signin = async (email, password) => {
		const headers = new Headers({
			Accept: 'application/json',
			'Content-type': 'application/json',
		})
		const body = JSON.stringify({
			email,
			password,
			domain: window.location.hostname,
		})

		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/auth`,
			{ method: 'POST', headers, body },
		)
		if (!response.ok) {
			console.log('failed to login', response)
			const text = await response.text()
			const error = JSON.parse(text)
			throw new Error(error.message)
		}

		const ans = await response.json()
		return setUser(ans)
	}

	const signout = () => {
		clearSession()
	}

	/** @satisfies {Context.Auth['updatePassword']} */
	const updatePassword = async (token, email, newPassword) => {
		const headers = new Headers({
			'x-access-token': token,
			Accept: 'application/json',
			'Content-type': 'application/json',
		})
		const body = JSON.stringify({ email: email, password: newPassword })

		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/auth/reset/update-password`,
			{ method: 'POST', headers, body },
		)
		if (!response.ok) {
			console.log('failed to reset password', response)
			throw new Error(response.statusText)
		}

		const ans = await response.json()
		return setUser(ans)
	}

	const value = {
		user,
		loading,
		signin,
		signout,
		currentCompany,
		setCurrentCompany,
		updatePassword,
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

/**
 * Gets the authentication context for the current session, including helper
 * functions to help with managing the session.
 *
 * **Note:** the user and/or current company details may not be available if the
 * user is not yet signed in. Use `useAuthenticated` instead to ensure the
 * details are available.
 */
export default function useAuth() {
	const context = useContext(AuthContext)
	if (context === undefined) {
		throw new Error('useAuth must be used within a AuthProvider')
	}

	return context
}

/**
 * Gets the authenticated user and current company details, or throws an error
 * if the user is not yet authenticated.
 *
 * Use within the `RequireAuth` wrapper component or otherwise ensure that the
 * user is authenticated.
 */
export function useAuthenticated() {
	const { currentCompany, user } = useAuth()

	if (!currentCompany || !user) {
		throw new Error(
			'useAuthenticated must be used within a RequireAuth wrapper.',
		)
	}
	return { currentCompany, user }
}

export function usePossibleCustomerId() {
	const { currentCompany } = useAuth()
	const { impersonatedCustomer } = useImpersonation()
	if (impersonatedCustomer) {
		return impersonatedCustomer.id
	}
	if (currentCompany?.isClient === false) {
		return currentCompany.accountId
	}
	return undefined
}

export function useCustomerId() {
	const customerId = usePossibleCustomerId()
	if (!customerId) {
		throw new Error('Must be using a customer account to get the customer ID.')
	}
	return customerId
}

/**
 * @param {object} props
 * @param {ReactNode} [props.children]
 */
export function RequireAuth({ children = <Outlet /> }) {
	const { user, currentCompany, loading } = useAuth()
	const location = useLocation()
	if (loading) {
		return <LoadingPage message="Securing Site" />
	}
	return user && currentCompany ? (
		children
	) : (
		<Navigate to="/login" replace state={{ path: location.pathname }} />
	)
}

/**
 * @param {object} props
 * @param {ReactNode} [props.children]
 * @param {string} [props.fallbackRoutePath]
 */
export function RequireAdmin({
	children = <Outlet />,
	fallbackRoutePath = '/',
}) {
	const { currentCompany } = useAuthenticated()
	const role = currentCompany.roleName.toLowerCase()
	return role === 'admin' || role === 'superuser' ? (
		children
	) : (
		<Navigate to={fallbackRoutePath} replace />
	)
}

/**
 * @param {object} props
 * @param {ReactNode} [props.children]
 * @param {string} [props.fallbackRoutePath]
 */
export function RequireClient({
	children = <Outlet />,
	fallbackRoutePath = '/',
}) {
	const { currentCompany } = useAuthenticated()
	const roleType = currentCompany.roleType.toLowerCase()
	return roleType === 'client' || roleType === 'superadmin' ? (
		children
	) : (
		<Navigate to={fallbackRoutePath} replace />
	)
}

/**
 * @param {object} props
 * @param {ReactNode} [props.children]
 * @param {string} [props.fallbackRoutePath]
 */
export function RequireCustomer({
	children = <Outlet />,
	fallbackRoutePath = '/',
}) {
	const { currentCompany } = useAuthenticated()
	const { impersonatedCustomer } = useImpersonation()
	const roleType = currentCompany.roleType.toLowerCase()
	return roleType === 'customer' || impersonatedCustomer ? (
		children
	) : (
		<Navigate to={fallbackRoutePath} replace />
	)
}

/**
 * @param {string} email
 * @returns {Promise<void>}
 */
export const resetPassword = async (email) => {
	const headers = new Headers({
		Accept: 'application/json',
		'Content-Type': 'application/json',
	})
	const body = JSON.stringify({ email })

	const response = await fetch(
		`${process.env.REACT_APP_API_BASE_URL}/api/auth/reset`,
		{
			method: 'POST',
			headers,
			body,
		},
	)
	if (!response.ok) {
		console.log('failed to send reset password request/message', response)
		throw new Error(response.statusText)
	}
}
