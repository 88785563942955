// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'
import { EpicorBaseService } from './base.service'

/**
 * @extends EpicorBaseService<'payments'>
 */
class EpicorPaymentService extends EpicorBaseService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		super('payments', http)
		this.#http = http
	}

	#http

	/**
	 * @param {Id<'customers'>} customerId
	 * @param {Id<'payments', string>} paymentIntentId
	 */
	getPaymentReceipt(customerId, paymentIntentId) {
		if (!paymentIntentId.startsWith('pi_')) {
			throw new Error(
				`Invalid paymentIntent: "${paymentIntentId}". PaymentIntent must start with "pi_".`,
			)
		}
		return this.#http.get(
			`/api/customers/${customerId}/payments/${paymentIntentId}`,
		)
	}
}

export function useEpicorPaymentService() {
	const http = useHttp()
	return useMemo(() => new EpicorPaymentService(http), [http])
}
