// @ts-check

import { Col } from 'react-bootstrap'
import { PageHeader } from './page-header.component'

/** @import {  ReactNode } from 'react' */

/**
 * @param {object} props
 * @param {ReactNode} [props.action]
 * @param {ReactNode} [props.breadcrumbs]
 * @param {ReactNode} [props.pagination]
 */
export const ListPageHeader = ({ action, breadcrumbs, pagination }) => {
	return (
		<PageHeader
			breadcrumbs={breadcrumbs}
			actionColumn={
				<Col className="d-flex flex-column align-items-end gap-2">
					{action}
					{pagination}
				</Col>
			}
		/>
	)
}
