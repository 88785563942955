// @ts-check

//TODO: Move to Database?
const stripeCardFeePercent = 0.029
const stripeCardFixedFee = 0.3
const stripeCardFeeMaximum = Number.POSITIVE_INFINITY
const stripeACHFeePercent = 0.008
const stripeACHFixedFee = 0
const stripeACHFeeMaximum = 5
const elevateFeePercent = 0.008
const elevateFeeMaximum = 5

/** @typedef {'card' | 'us_bank_account'} PaymentMethod */

/**
 * @param {object} args
 * @param {number} args.paymentAmount
 * @param {string} args.currency
 * @param {PaymentMethod} args.paymentMethod
 * @param {'client' | 'customer'} args.feePayer
 */
const getPaymentBreakdown = ({
	paymentAmount,
	currency,
	paymentMethod,
	feePayer,
}) => {
	const elevateFee = getElevateFee(paymentAmount, currency)
	const { feePercent, fixedFee, maximumFee } = getStripeRates(paymentMethod)

	// Context for the derivation of the surcharge equation:
	//
	// Based on the math (a geometric series) previously provided by Stripe
	// (https://web.archive.org/web/20240525115237/https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers)
	// the amount that should be charged would be:
	//
	// amountWithSurcharge = (amount + stripeFixedFee) / (1 - stripeFeePercent)
	//
	// in order to pass on the fee to the customer as a surcharge. Therefore, the
	// actual surcharge amount would be the difference between this, the fee
	// without the surcharge, and the original amount:
	//
	// surcharge = amountWithSurcharge - feeWithoutSurcharge - amount
	//
	// where the standard Stripe fee without including the surcharge is:
	//
	// feeWithoutSurcharge = amount * stripeFeePercent + stripeFixedFee
	//
	// which all expands and then simplifies to:
	//
	// surcharge =
	// 	(amount + stripeFixedFee) / (1 - stripeFeePercent)
	// 	- (amount * stripeFeePercent + stripeFixedFee)
	// 	- amount
	// 	=
	// 	(amount * stripeFeePercent + stripeFixedFee)
	// 	* stripeFeePercent / (1 - stripeFeePercent)
	// 	=
	// 	feeWithoutSurcharge * stripeFeePercent / (1 - stripeFeePercent)
	//
	const surchargeRate = feePercent / (1 - feePercent)

	const stripeFee = feePercent * paymentAmount + fixedFee
	const stripeFeeSurcharge = stripeFee * surchargeRate

	// If the Elevate fee is included in the payment amount, this is the
	// additional Stripe fee and surcharge amount that will incurs.
	const stripeFeeForElevateFee = feePercent * elevateFee
	const stripeFeeForElevateFeeSurcharge = stripeFeeForElevateFee * surchargeRate

	const cappedStripeFee = Math.min(
		roundToCents(stripeFee, { roundingMode: 'up' }),
		maximumFee,
	)
	const cappedStripeFeeWithSurcharge = Math.min(
		roundToCents(
			stripeFee +
				stripeFeeSurcharge +
				stripeFeeForElevateFee +
				stripeFeeForElevateFeeSurcharge,
			{ roundingMode: 'up' },
		),
		maximumFee,
	)

	switch (feePayer) {
		case 'client':
			return {
				elevateFee,
				stripeFee: cappedStripeFee,
				totalCharge: roundToCents(paymentAmount),
			}
		case 'customer':
			return {
				elevateFee,
				stripeFee: cappedStripeFeeWithSurcharge,
				totalCharge: roundToCents(
					paymentAmount + cappedStripeFeeWithSurcharge + elevateFee,
				),
			}
		default:
			throw new Error(`Unexpected fee payer: ${feePayer}`)
	}
}

/**
 * @param {PaymentMethod} paymentMethod
 */
const getStripeRates = (paymentMethod) => {
	switch (paymentMethod) {
		case 'card':
			return {
				feePercent: stripeCardFeePercent,
				fixedFee: stripeCardFixedFee,
				maximumFee: stripeCardFeeMaximum,
			}
		case 'us_bank_account':
			return {
				feePercent: stripeACHFeePercent,
				fixedFee: stripeACHFixedFee,
				maximumFee: stripeACHFeeMaximum,
			}
		default:
			throw new Error(`Unexpected payment method: ${paymentMethod}`)
	}
}

/**
 * @param {number} paymentAmount
 * @param {string} currency
 */
const getElevateFee = (paymentAmount, currency) => {
	const fee = roundToCents(paymentAmount * elevateFeePercent, {
		roundingMode: 'up',
	})
	//TODO: Set Maximum of 5 USD. Needs currency conversion
	return currency.toLowerCase() === 'usd'
		? Math.min(fee, elevateFeeMaximum)
		: fee
}

/**
 * @param {number} value
 * @param {object} [options]
 * @param {'nearest' | 'up'} [options.roundingMode]
 */
const roundToCents = (value, { roundingMode = 'nearest' } = {}) =>
	Math[roundingMode === 'up' ? 'ceil' : 'round'](value * 100) / 100

module.exports = {
	getPaymentBreakdown,
	roundToCents,
}
