// @ts-check

/**
 * @param {object} props
 * @param {import("react").ReactNode} props.children
 */
export function AuthenticationFormContainer({ children }) {
	return (
		<div
			className="
				d-flex justify-content-center align-items-center
				h-100 p-3
			"
		>
			<div
				className="
					authentication-form-container-width
					d-grid gap-4
					text-center
					m-0 p-5
					rounded-4 shadow-lg
				"
			>
				{children}
			</div>
		</div>
	)
}
