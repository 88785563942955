// @ts-check

import { Fragment } from 'react'

/**
 * @param {object} props
 * @param {Api.CustomerDetails | Api.CustomerShipToAddress} props.customer
 */
function CustomerAddress({ customer }) {
	const [companyName, ...addressLines] = customer.addrList
		.split('~')
		.map((part) => part.trim())
		.filter((part) => part)
	return (
		<ul className="info-list list-unstyled mb-0">
			{companyName && (
				<li>
					<strong>{companyName}</strong>
					{addressLines.map((line, index) => (
						<Fragment key={index}>
							<br />
							{line}
						</Fragment>
					))}
				</li>
			)}
			{'phoneNum' in customer && customer.phoneNum && (
				<li>
					<strong>Phone: </strong>
					{customer.phoneNum}
				</li>
			)}
			{'faxNum' in customer && customer.faxNum && (
				<li>
					<strong>Fax: </strong>
					{customer.faxNum}
				</li>
			)}
			{'eMailAddress' in customer && customer.eMailAddress && (
				<li>
					<strong>Email: </strong>
					{customer.eMailAddress}
				</li>
			)}
		</ul>
	)
}

export default CustomerAddress
