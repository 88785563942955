import { Navigate } from 'react-router-dom'
import { ListPageHeader } from 'components/shared/header/list-page-header.component'
import { useSettings } from 'hooks/useSettings'
import InvoiceList from './invoices.list.component'

const Invoices = () => {
	const { settings } = useSettings()

	if (settings.invoiceDisableMenu) {
		return <Navigate to="/" replace />
	}

	return (
		<>
			<ListPageHeader breadcrumbs="Invoices" />
			<InvoiceList />
		</>
	)
}

export default Invoices
