// @ts-check

import { forwardRef, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'

const PasswordField = forwardRef(
	/**
	 * @param {object} props
	 * @param {string} props.name
	 * @param {string} [props.placeholder]
	 * @param {(event: import('react').ChangeEvent<HTMLInputElement>) => void} [props.onChange]
	 * @param {string} [props.value]
	 * @param {import('react').ForwardedRef<HTMLInputElement>} ref
	 */
	({ name, placeholder, onChange, value }, ref) => {
		const [isVisible, setIsVisible] = useState(false)

		return (
			<InputGroup>
				<Form.Control
					ref={ref}
					name={name}
					required
					size="lg"
					type={isVisible ? 'text' : 'password'}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
				/>
				<InputGroup.Text
					onClick={() => {
						setIsVisible((isVisible) => !isVisible)
					}}
				>
					<span style={{ minWidth: '3rem' }} role="button">
						{isVisible ? 'Hide' : 'Show'}
					</span>
				</InputGroup.Text>
				<Form.Control.Feedback type="invalid">
					Please enter {name}
				</Form.Control.Feedback>
			</InputGroup>
		)
	},
)

export default PasswordField
