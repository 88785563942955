import { useAuthenticated, usePossibleCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert, Modal, Button } from 'react-bootstrap'
import { useEpicorQueryService } from 'services/epicor'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import QueriesTable from './queries.table.component'

const QueriesList = ({ refresher, onEditQuery, companyRole, onRunQuery }) => {
	const { currentCompany } = useAuthenticated()
	const EpicorQueryService = useEpicorQueryService()
	const [queries, setQueries] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [query, setQuery] = useState(null)
	const customerId = usePossibleCustomerId()

	const fetchQueries = useCallback(async () => {
		setLoading(true)
		try {
			const response = await EpicorQueryService.findAll(
				currentCompany.environmentId,
				currentCompany.companyId,
			)
			setQueries(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [EpicorQueryService, currentCompany])

	const downloadQuery = async (queryId, queryName, outputType = 'json') => {
		EpicorQueryService.execute(customerId, queryId, outputType)
			.then((response) => {
				var link = document.createElement('a')
				let file
				let fileURL
				switch (outputType) {
					case 'csv':
						file = new Blob(response.data.split('\n'), {
							type: 'text/csv;charset=utf-8;',
						})
						break
					case 'xml':
						file = new Blob([response.data], {
							type: 'text/xml;charset=utf-8;',
						})
						break
					case 'json':
						file = new Blob([JSON.stringify(response.data)], {
							type: 'text/json;charset=utf-8;',
						})
						break
					default:
						break
				}
				fileURL = URL.createObjectURL(file)
				link.download = `${currentCompany.company}_Query_${queryName}.${outputType}`
				link.href = fileURL
				link.click()
				link.remove()
			})
			.catch((error) => setError(error.message))
	}

	const showDeleteModal = (query) => {
		setQuery(query)
		setShowModal(true)
	}
	const closeDeleteModal = () => {
		setShowModal(false)
		refresher.refresh()
	}

	const deleteQuery = () => {
		setShowModal(false)
		EpicorQueryService.deleteById(
			currentCompany.environmentId,
			currentCompany.companyId,
			query.id,
		)
			.then(() => {
				refresher.refresh(`Deleted Query ${query.queryID}`)
			})
			.catch((error) => setError(error.message))
	}

	useEffect(() => {
		fetchQueries()
	}, [fetchQueries, refresher.id])

	return (
		<>
			<Modal show={showModal} onHide={closeDeleteModal} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>Delete Query</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure you want to delete {query?.queryID}?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={deleteQuery}>
						Yes
					</Button>
					<Button variant="secondary" onClick={closeDeleteModal}>
						No
					</Button>
				</Modal.Footer>
			</Modal>
			{error ? (
				<Alert variant="danger" dismissible onClose={() => setError(null)}>
					{error}
				</Alert>
			) : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<QueriesTable
					queries={queries}
					onDownloadQuery={downloadQuery}
					onEditQuery={onEditQuery}
					onDeleteQuery={showDeleteModal}
					companyRole={companyRole}
					onRunQuery={onRunQuery}
				/>
			)}
		</>
	)
}

export default QueriesList
