// @ts-check

import { useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { CheckCircle } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { AuthenticationFormContainer } from 'components/shared/authentication-form-container.component'
import { resetPassword } from 'hooks/useAuth'

/**
 * @param {object} props
 * @param {() => void} props.onEmailSent
 */
const ResetPasswordForm = ({ onEmailSent }) => {
	const [isValidated, setIsValidated] = useState(false)
	const [errorMessage, setErrorMessage] = useState(
		/** @type {string | null} */ (null),
	)
	const [email, setEmail] = useState('')

	// authenticate against Signin API.  if the returned user has more then companies solicit
	// which one he wants to home into
	/** @param {import('react').FormEvent<HTMLFormElement>} event */
	const reset = async (event) => {
		event.preventDefault()
		setIsValidated(true)
		if (!event.currentTarget.checkValidity()) {
			return
		}
		try {
			setErrorMessage(null)
			await resetPassword(email)
			onEmailSent()
		} catch (error) {
			setErrorMessage('Invalid email.')
		}
	}

	const canSubmit = email !== ''

	return (
		<Form
			autoComplete="on"
			onSubmit={reset}
			className="d-grid gap-4"
			noValidate
			validated={isValidated}
		>
			<Form.Group controlId="email">
				<Form.Control
					required
					size="lg"
					type="email"
					placeholder="Email"
					value={email}
					onChange={(event) => {
						setEmail(event.target.value)
					}}
				/>
				<Form.Control.Feedback type="invalid">
					Please enter a valid email address
				</Form.Control.Feedback>
			</Form.Group>
			<Button
				size="lg"
				variant={canSubmit ? 'primary' : 'secondary'}
				type="submit"
				disabled={!canSubmit}
			>
				Reset Password
			</Button>
			{errorMessage && (
				<Alert variant="danger" className="m-0">
					{errorMessage}
				</Alert>
			)}
		</Form>
	)
}

const ResetPassword = () => {
	const [wasEmailSent, setWasEmailSent] = useState(false)

	return (
		<AuthenticationFormContainer>
			<h2 className="m-0">Reset Password</h2>
			{!wasEmailSent ? (
				<ResetPasswordForm
					onEmailSent={() => {
						setWasEmailSent(true)
					}}
				/>
			) : (
				<>
					<CheckCircle
						className="w-100"
						style={{ fontSize: '5rem', color: '#11BD22' }}
					/>
					<p className="m-0">
						A link has been sent to your email inbox to reset your password.
					</p>
				</>
			)}
			<Link to="/login" className="link-dark text-decoration-none">
				Back to Login
			</Link>
		</AuthenticationFormContainer>
	)
}

export default ResetPassword
