// @ts-check

import { useState, useEffect } from 'react'
import { Button, Form, InputGroup, Alert, Spinner } from 'react-bootstrap'
import { CheckCircle } from 'react-bootstrap-icons'
import { useParams, useNavigate, Link } from 'react-router-dom'
import CompanyChooser from './company-chooser.component'
import useAuth from 'hooks/useAuth'
import { checkPasswordComplexity } from '@elevate/shared/validation'
import { AuthenticationFormContainer } from 'components/shared/authentication-form-container.component'
import PasswordField from 'components/shared/password.field.component'

const NewPassword = () => {
	const { accessToken } = useParams()

	const [errorMessage, setErrorMessage] = useState(
		/** @type {string | null} */ (null),
	)
	const [email, setEmail] = useState('')
	const { currentCompany, user } = useAuth()
	const navigate = useNavigate()

	useEffect(() => {
		if (!accessToken) {
			navigate('/', { replace: true })
			return
		}

		/** @param {string} accessToken */
		async function validateAccessToken(accessToken) {
			try {
				setErrorMessage(null)
				const response = await fetch(
					`${process.env.REACT_APP_API_BASE_URL}/api/auth/reset`,
					{
						headers: {
							'x-access-token': accessToken,
						},
					},
				)
				if (!response.ok) {
					setErrorMessage('Your reset password failed to be authenticated.')
					return
				}

				const data = await response.json()
				setEmail(typeof data.email === 'string' ? data.email : null)
			} catch (error) {
				console.error('Error:', error)
				setErrorMessage('An error occurred while processing your request.')
			}
		}
		validateAccessToken(accessToken)
	}, [accessToken, navigate])

	return (
		<AuthenticationFormContainer>
			<h2>Update Password</h2>
			{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
			{!email ? (
				!errorMessage && (
					<div className="w-100">
						<Spinner />
					</div>
				)
			) : !user ? (
				<NewPasswordForm email={email} />
			) : (
				<>
					<CheckCircle
						className="w-100"
						style={{ fontSize: '5rem', color: '#11BD22' }}
					/>
					<p className="m-0">Password has been changed successfully.</p>
					{currentCompany ? (
						<CompanyChooser user={user} />
					) : (
						<Link to="/" className="link-dark text-decoration-none">
							Home
						</Link>
					)}
				</>
			)}
		</AuthenticationFormContainer>
	)
}

/**
 * @param {object} props
 * @param {string} props.email
 */
const NewPasswordForm = ({ email }) => {
	const { accessToken } = useParams()

	const [isValidated, setIsValidated] = useState(false)
	const [errorMessage, setErrorMessage] = useState(
		/** @type {string | null} */ (null),
	)
	const [passwordError, setPasswordError] = useState(
		/** @type {string | null} */ (null),
	)
	const [newPassword, setNewPassword] = useState('')
	const [passwordConfirmation, setPasswordConfirmation] = useState('')
	const { updatePassword } = useAuth()
	const navigate = useNavigate()

	/** @param {import('react').FormEvent<HTMLFormElement>} event */
	const sendPassword = async (event) => {
		event.preventDefault()

		if (!accessToken) {
			navigate('/', { replace: true })
			return
		}

		setIsValidated(true)
		if (!event.currentTarget.checkValidity()) {
			return
		}

		if (newPassword !== passwordConfirmation) {
			setPasswordError('The passwords provided do not match.')
			return
		}

		const complexityCheck = checkPasswordComplexity(newPassword)
		if (!complexityCheck.isValid) {
			setPasswordError(complexityCheck.message)
			return
		}

		try {
			setErrorMessage(null)
			setPasswordError(null)
			await updatePassword(accessToken, email, newPassword)
		} catch (err) {
			// TODO Axios should get better error handling.
			setErrorMessage('Your new password failed to update.')
		}
	}

	const canSubmit =
		email !== '' && newPassword !== '' && passwordConfirmation !== ''

	return (
		<>
			<Form
				autoComplete="on"
				onSubmit={sendPassword}
				className="d-grid gap-4"
				noValidate
				validated={isValidated}
			>
				<Alert variant="primary text-start m-0">
					Password must contain at least:
					<ul className="mb-0">
						<li>8 Characters</li>
						<li>1 Uppercase Letter</li>
						<li>1 Lowercase Letter</li>
						<li>1 Number</li>
						<li>1 Special Character</li>
						<li>Not be too weak</li>
					</ul>
				</Alert>
				{passwordError && (
					<Alert variant="danger" className="m-0">
						{passwordError}
					</Alert>
				)}

				<InputGroup>
					<InputGroup.Text>{email}</InputGroup.Text>
				</InputGroup>
				<PasswordField
					name="password"
					placeholder="Password"
					value={newPassword}
					onChange={(event) => {
						setNewPassword(event.target.value)
					}}
				/>
				<PasswordField
					name="password verification"
					placeholder="Verify Password"
					value={passwordConfirmation}
					onChange={(event) => {
						setPasswordConfirmation(event.target.value)
					}}
				/>
				<Button
					size="lg"
					variant={canSubmit ? 'primary' : 'secondary'}
					type="submit"
					disabled={!canSubmit}
				>
					Save
				</Button>
			</Form>
			{errorMessage && (
				<Alert variant="danger" className="m-0">
					{errorMessage}
				</Alert>
			)}
		</>
	)
}

export default NewPassword
