// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'
import { useSession } from 'hooks/useSession'

class CustomerService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 * @param {Account} currentCompany
	 */
	constructor(http, currentCompany) {
		this.#http = http
		this.#currentCompany = currentCompany
	}

	#http
	#currentCompany

	/**
	 * @param {object} [options]
	 * @param {Account} [options.company]
	 * @param {string} [options.search]
	 * @returns {AxiosReturn<readonly Api.Customer[]>}
	 */
	findAll({ company = this.#currentCompany, search } = {}) {
		let url = `/api/environments/${company.environmentId}/company/${company.companyId}/customers`
		if (search) {
			url += `?search=${search}`
		}
		return this.#http.get(url)
	}

	paymentListCompany({ company = this.#currentCompany } = {}) {
		return this.#http.get(
			`/api/environments/${company.environmentId}/company/${company.companyId}/payments`,
		)
	}
}

export function useCustomerService() {
	const http = useHttp()
	const { currentCompany } = useSession()
	if (!currentCompany) {
		throw new Error(
			'Customer service must have currentCompany loaded in the session.',
		)
	}

	return useMemo(
		() => new CustomerService(http, currentCompany),
		[currentCompany, http],
	)
}
