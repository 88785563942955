import { useCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useEpicorHelpDeskService } from 'services/epicor'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import HelpDesksTable from './helpdesks.table.component'

const HelpDesksList = ({ refresher }) => {
	const EpicorHelpDeskService = useEpicorHelpDeskService()
	const [cases, setCases] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const customerId = useCustomerId()

	const fetchCases = useCallback(async () => {
		setLoading(true)
		try {
			const response = await EpicorHelpDeskService.findAll(customerId)
			setCases(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [EpicorHelpDeskService, customerId])

	useEffect(() => {
		fetchCases()
	}, [fetchCases, refresher.id])

	return (
		<>
			{error ? (
				<Alert dismissible variant="danger">
					{error}
				</Alert>
			) : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<HelpDesksTable cases={cases} /> //TODO: Display Details on Click
			)}
		</>
	)
}

export default HelpDesksList
