import { Modal } from 'react-bootstrap'
import NewOrder from './new-order/new.order.component'

const OrderForm = ({ show = false, onClose, onOperationCompleted }) => {
	const notifyCreated = (data) => {
		onOperationCompleted?.(
			`A new Order has been added. Order number: ${data.orderNumber}`,
		)
		onClose?.()
	}

	return (
		<Modal size={'xl'} show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Create Order</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<NewOrder onSubmitted={notifyCreated} />
			</Modal.Body>
		</Modal>
	)
}

export default OrderForm
