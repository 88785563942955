// @ts-check

import _ from 'lodash'
import { useState, useEffect } from 'react'
import {
	Alert,
	Button,
	Col,
	Form,
	Row,
	Spinner,
	Container,
} from 'react-bootstrap'
import { useCustomerId } from 'hooks/useAuth'
import { useSettings } from 'hooks/useSettings'
import {
	useEpicorCustomerService,
	useEpicorHelpDeskService,
	useEpicorInvoiceService,
	useEpicorOrderService,
	useEpicorQuoteService,
	useEpicorRMAService,
} from 'services/epicor'
import AsyncPartTypeahead from 'components/shared/part.search.component'
import { getErrorMessage } from 'components/utilities'

/** @import { EpicorBaseService } from 'services/epicor/base.service' */

/**
 * @param {object} props
 * @param {(data: unknown) => void} props.onSubmitted
 */
const NewHelpDesk = ({ onSubmitted }) => {
	const { settings } = useSettings()
	const EpicorHelpDeskService = useEpicorHelpDeskService()
	const EpicorCustomerService = useEpicorCustomerService()
	const EpicorOrderService = useEpicorOrderService()
	const EpicorInvoiceService = useEpicorInvoiceService()
	const EpicorQuoteService = useEpicorQuoteService()
	const EpicorRMAService = useEpicorRMAService()
	const [error, setError] = useState(/** @type {string | null} */ (null))
	const [shipToAddresses, setShipToAddresses] = useState(
		/** @type {readonly Api.CustomerShipToAddress[]} */ ([]),
	)
	const [shipToNum, setShipToNum] = useState('')
	const [conNum, setConNum] = useState(0)
	const [selectedOrder, setSelectedOrder] = useState(
		/** @type {Api.Order | undefined} */ (undefined),
	)
	const [selectedRMA, setSelectedRMA] = useState(
		/** @type {Api.RMA | undefined} */ (undefined),
	)
	const [selectedInvoice, setSelectedInvoice] = useState(
		/** @type {Api.Invoice | undefined} */ (undefined),
	)
	const [selectedQuote, setSelectedQuote] = useState(
		/** @type {Api.Quote | undefined} */ (undefined),
	)
	const [contacts, setContacts] = useState(
		/** @type {readonly { readonly conNum: number, readonly name: string }[]} */ ([]),
	)
	const [partNum, setPartNum] = useState('')
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [savingHelpDesk, setSavingHelpDesk] = useState(false)

	const orders = useResourceLoader(
		EpicorOrderService,
		(a, b) => b.orderNum - a.orderNum,
		setError,
	)
	const invoices = useResourceLoader(
		EpicorInvoiceService,
		(a, b) => b.invoiceNum - a.invoiceNum,
		setError,
	)
	const quotes = useResourceLoader(
		EpicorQuoteService,
		(a, b) => b.quoteNum - a.quoteNum,
		setError,
	)
	const rmas = useResourceLoader(
		EpicorRMAService,
		(a, b) => b.rMANum - a.rMANum,
		setError,
	)

	const customerId = useCustomerId()

	/** @param {React.FormEvent<HTMLFormElement>} event */
	const submit = async (event) => {
		event.preventDefault()
		setSavingHelpDesk(true)

		try {
			const response = await EpicorHelpDeskService.create(
				{
					helpdesk: {
						shipTo: shipToNum,
						issueSummary: title,
						issueText: description,
						orderNum: selectedOrder?.orderNum ?? null,
						rmaNum: selectedRMA?.rMANum ?? null,
						invoiceNum: selectedInvoice?.invoiceNum ?? null,
						quoteNum: selectedQuote?.quoteNum ?? null,
						partNum: partNum,
						conNum: conNum,
					},
				},
				customerId,
			)
			if ('error' in response.data) {
				const { error } = response.data
				/** @type {unknown} */
				const errorText = _.get(error, '[0].ErrorText')
				setError(
					typeof errorText === 'string' ? errorText : JSON.stringify(error),
				)
			} else {
				onSubmitted(response.data)
			}
		} catch (error) {
			setError(getErrorMessage(error))
		} finally {
			setSavingHelpDesk(false)
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [infoResponse, contactsResponse] = await Promise.all([
					EpicorCustomerService.getInfo(customerId),
					EpicorCustomerService.getContacts(customerId),
				])
				setShipToAddresses(
					[...infoResponse.data.shipToes].sort((a, b) =>
						a.name.localeCompare(b.name),
					),
				)
				setContacts(
					contactsResponse.data.value
						.map((contact) => ({
							conNum: contact.CustNum,
							name: `${contact.FirstName} ${contact.LastName} (${contact.EMailAddress})`,
						}))
						.sort((a, b) => a.name.localeCompare(b.name)),
				)
			} catch (error) {
				setError(getErrorMessage(error))
			}
		}
		fetchData()
	}, [EpicorCustomerService, customerId])

	return (
		<Form onSubmit={submit}>
			<Container fluid>
				<Row>
					<Col xs={12} lg={6}>
						<Form.Group controlId="form.shipTo">
							<Form.Label>Shipping Address</Form.Label>
							<Form.Select
								name="shipTo"
								onChange={(e) => setShipToNum(e.target.value)}
								value={shipToNum || ''}
							>
								{shipToAddresses.map((address) => (
									<option key={address.shipToNum} value={address.shipToNum}>
										{address.addrList.replace(/~/g, ' ')}
									</option>
								))}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="form.contact">
							<Form.Label>Contact</Form.Label>
							<Form.Select
								name="contact"
								onChange={(e) => {
									setConNum(Number(e.target.value))
								}}
								value={conNum || ''}
							>
								{contacts.map((contact) => (
									<option key={contact.conNum} value={contact.conNum}>
										{contact.name}
									</option>
								))}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="form.orderNum">
							<Form.Label>Order</Form.Label>
							<Form.Select
								name="order"
								onChange={(event) => {
									const id = Number(event.target.value) || null
									const order = orders.list.find(
										(order) => order.orderNum === id,
									)
									setSelectedOrder(order)
								}}
								value={selectedOrder?.orderNum ?? 0}
								onClick={() => {
									orders.load()
								}}
							>
								{orders.isLoading ? (
									<option value="loading" disabled>
										Loading...
									</option>
								) : (
									<>
										<option value="0" disabled hidden>
											Select One...
										</option>
										{orders.list.map((order) => (
											<option key={order.orderNum} value={order.orderNum}>
												{order.orderNum}
											</option>
										))}
									</>
								)}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="form.invoiceNum">
							<Form.Label>Invoice</Form.Label>
							<Form.Select
								name="invoice"
								onChange={(event) => {
									const id = Number(event.target.value) || null
									const invoice = invoices.list.find(
										(invoice) => invoice.invoiceNum === id,
									)
									setSelectedInvoice(invoice)
								}}
								value={selectedInvoice?.invoiceNum ?? 0}
								onClick={() => {
									invoices.load()
								}}
							>
								{invoices.isLoading ? (
									<option value="loading" disabled>
										Loading...
									</option>
								) : (
									<>
										<option value="0" disabled hidden>
											Select One...
										</option>
										{invoices.list.map((invoice) => (
											<option
												key={invoice.invoiceNum}
												value={invoice.invoiceNum}
											>
												{invoice.invoiceNum}
											</option>
										))}
									</>
								)}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="form.quoteNum">
							<Form.Label>Quote</Form.Label>
							<Form.Select
								name="quote"
								onChange={(event) => {
									const id = Number(event.target.value) || null
									const quote = quotes.list.find(
										(quote) => quote.quoteNum === id,
									)
									setSelectedQuote(quote)
								}}
								value={selectedQuote?.quoteNum ?? 0}
								onClick={() => {
									quotes.load()
								}}
							>
								{quotes.isLoading ? (
									<option value="loading" disabled>
										Loading...
									</option>
								) : (
									<>
										<option value="0" disabled hidden>
											Select One...
										</option>
										{quotes.list.map((quote) => (
											<option key={quote.quoteNum} value={quote.quoteNum}>
												{quote.quoteNum}
											</option>
										))}
									</>
								)}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="form.rmaNum">
							<Form.Label>RMA</Form.Label>
							<Form.Select
								name="rma"
								onChange={(event) => {
									const id = Number(event.target.value) || null
									const rma = rmas.list.find((rma) => rma.rMANum === id)
									setSelectedRMA(rma)
								}}
								value={selectedRMA?.rMANum ?? 0}
								onClick={() => {
									rmas.load()
								}}
							>
								{rmas.isLoading ? (
									<option value="loading" disabled>
										Loading...
									</option>
								) : (
									<>
										<option value="0" disabled hidden>
											{' '}
											Select One...
										</option>
										{rmas.list.map((rma) => (
											<option key={rma.rMANum} value={rma.rMANum}>
												{rma.rMANum}
											</option>
										))}
									</>
								)}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="form.partNum">
							<Form.Label>Part</Form.Label>
							<AsyncPartTypeahead
								ident="partNumSelect"
								onChange={(selected) => {
									setPartNum(selected.partNum)
								}}
							/>
						</Form.Group>
					</Col>
					<Col xs={12} lg={6}>
						<Form.Group controlId="form.title">
							<Form.Label>Title</Form.Label>
							<Form.Control
								name="title"
								placeholder="Title"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								className="mb-3"
								required
							/>
						</Form.Group>
						<Form.Group controlId="form.desription">
							<Form.Label>Description</Form.Label>
							<Form.Control
								as="textarea"
								rows={10}
								name="description"
								placeholder="Please, put your case description here."
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								className="mb-3"
								required
							/>
						</Form.Group>
						{settings.helpdeskNewMessage && (
							<Alert variant="warning">{settings.helpdeskNewMessage}</Alert>
						)}
						<Button
							variant="primary mt-auto"
							type="submit"
							disabled={savingHelpDesk || title === '' || description === ''}
							className="mb-3"
						>
							{savingHelpDesk ? (
								<Spinner animation="border" role="status">
									<span className="visually-hidden">
										Saving Case, please wait...
									</span>
								</Spinner>
							) : (
								'Send Case'
							)}
						</Button>
						{error && (
							<Alert
								onClose={() => setError(null)}
								variant="danger"
								dismissible
							>
								{error}
							</Alert>
						)}
					</Col>
				</Row>
			</Container>
		</Form>
	)
}

/**
 * @template {string} ResourceName
 * @template Resource
 * @param {EpicorBaseService<ResourceName, Resource>} service
 * @param {(a: NoInfer<Resource>, b: NoInfer<Resource>) => number} comparer
 * @param {(errorMessage: string) => void} setErrorMessage
 */
const useResourceLoader = (service, comparer, setErrorMessage) => {
	const [list, setList] = useState(/** @type {readonly Resource[]} */ ([]))
	const [isLoading, setIsLoading] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)

	const customerId = useCustomerId()

	return {
		list,
		isLoading,
		async load() {
			if (!isLoaded) {
				setIsLoading(true)
				try {
					const response = await service.findAll(customerId)
					setList([...response.data].sort(comparer))
				} catch (error) {
					setErrorMessage(getErrorMessage(error))
				} finally {
					setIsLoading(false)
					setIsLoaded(true)
				}
			}
		},
	}
}

export default NewHelpDesk
