import { forwardRef } from 'react'
import { ThreeDotsVertical } from 'react-bootstrap-icons'

export const CustomToggle = forwardRef(({ children, onClick }, ref) => (
	<ThreeDotsVertical
		className="text-muted"
		role="button"
		ref={ref}
		onClick={(e) => {
			e.preventDefault()
			onClick(e)
		}}
	>
		{children}
	</ThreeDotsVertical>
))

export const CustomMenu = forwardRef(
	({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
		return (
			<div
				ref={ref}
				style={style}
				className={className}
				aria-labelledby={labeledBy}
			>
				<ul className="list-unstyled">{children}</ul>
			</div>
		)
	},
)
