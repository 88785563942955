// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'
import { EpicorBaseService } from './base.service'

/**
 * @extends EpicorBaseService<'helpdesks'>
 */
class EpicorHelpDeskService extends EpicorBaseService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		super('helpdesks', http)
	}
}

export function useEpicorHelpDeskService() {
	const http = useHttp()
	return useMemo(() => new EpicorHelpDeskService(http), [http])
}
