// @ts-check

import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'
import { jwtDecode } from 'jwt-decode'
import Cookies from 'js-cookie'
import { useImpersonation } from './useImpersonation'

/**
 * @param {Api.UserAuth} apiUser
 */
function parseUser(apiUser) {
	const accounts = apiUser.accounts.map((account) =>
		account.isClient
			? /** @type {const} */ ({
					...account,
					key: `client-${account.accountId}`,
				})
			: /** @type {const} */ ({
					...account,
					key: `customer-${account.accountId}`,
				}),
	)
	return { ...apiUser, accounts }
}

/**
 * @param {User} user
 * @param {string} companyIdCookieString
 * @param {string} isClientCookieString
 */
function findCompanyByIdAndType(
	user,
	companyIdCookieString,
	isClientCookieString,
) {
	const companyId = parseInt(companyIdCookieString, 10)
	const isClient = isClientCookieString === 'true'
	return (
		user.accounts.find(
			(account) =>
				account.companyId === companyId && account.isClient === isClient,
		) ?? null
	)
}

const SessionContext = createContext(
	/** @type {Context.Session | undefined} */ (undefined),
)

export function useSession() {
	const context = useContext(SessionContext)
	if (context === undefined) {
		throw new Error('useSession must be used within a SessionProvider.')
	}
	return context
}

/**
 * @param {import('react').PropsWithChildren} props
 */
export function SessionProvider({ children }) {
	const {
		impersonatedCustomer,
		setImpersonatedCustomer,
		unsetImpersonatedCustomer,
	} = useImpersonation()

	const [user, setUser] = useState(/** @type {User | null} */ (null))
	const [currentCompany, setCurrentCompany] = useState(
		/** @type {Account | null} */ (null),
	)
	const [loading, setLoading] = useState(true)

	const clearSession = useCallback(() => {
		sessionStorage.clear()
		Cookies.remove('user')
		Cookies.remove('currentCompany')
		// This will clear the cookie via the effect below.
		unsetImpersonatedCustomer()
		setUser(null)
		setCurrentCompany(null)
	}, [unsetImpersonatedCustomer])

	/** @param {Api.UserAuth} apiUser */
	const setUserSession = (apiUser) => {
		if (apiUser.accessToken) {
			sessionStorage.setItem('accessToken', apiUser.accessToken)
		}

		const user = parseUser(apiUser)
		setUser(user)

		if (user.accounts.length === 1) {
			setCurrentCompany(user.accounts[0])
		}

		return user
	}

	useEffect(() => {
		const accessToken = sessionStorage.getItem('accessToken')
		if (accessToken) {
			const { exp: expirationTimeInSeconds = 0 } = jwtDecode(accessToken)
			const expirationTimeInMilliseconds = expirationTimeInSeconds * 1000

			if (Date.now() >= expirationTimeInMilliseconds) {
				clearSession()
				return
			}

			const timeout = setTimeout(() => {
				clearSession()
			}, expirationTimeInMilliseconds - Date.now())

			const getSession = async () => {
				try {
					const response = await fetch(
						`${process.env.REACT_APP_API_BASE_URL}/api/auth`,
						{
							method: 'GET',
							headers: {
								'x-access-token': accessToken,
							},
						},
					)

					const user = setUserSession(await response.json())

					const currentCompanyIdCookie = Cookies.get('currentCompanyId')
					const currentCompanyIsClientCookie = Cookies.get(
						'currentCompanyIsClient',
					)
					if (currentCompanyIdCookie && currentCompanyIsClientCookie) {
						try {
							const currentCompany = findCompanyByIdAndType(
								user,
								currentCompanyIdCookie,
								currentCompanyIsClientCookie,
							)
							setCurrentCompany(currentCompany)
						} catch (error) {
							console.error('Error parsing currentCompany cookie:', error)
							setCurrentCompany(null)
						}
					}
				} catch (error) {
					console.error('Error fetching user data:', error)
					setUser(null)
					setCurrentCompany(null)
				} finally {
					setLoading(false)
				}
			}
			getSession()

			return () => {
				clearTimeout(timeout)
			}
		} else {
			setLoading(false)
		}
	}, [clearSession])

	// Load the impersonated user into the context from the cookie.
	useEffect(() => {
		const impersonatedCustomerCookie = Cookies.get('impersonatedCustomer')
		if (impersonatedCustomerCookie) {
			let impersonateCustomer
			try {
				impersonateCustomer = JSON.parse(impersonatedCustomerCookie)
			} catch (error) {
				console.error('Error parsing impersonatedCustomer cookie:', error)
			}
			setImpersonatedCustomer(impersonateCustomer)
		}
	}, [setImpersonatedCustomer])

	// Update the cookie based on changes to the impersonated user context.
	useEffect(() => {
		if (impersonatedCustomer) {
			Cookies.set(
				'impersonatedCustomer',
				JSON.stringify(impersonatedCustomer),
				{
					expires: 1,
					sameSite: 'Strict',
					secure: true,
				},
			)
		} else {
			Cookies.remove('impersonatedCustomer')
		}
	}, [impersonatedCustomer])

	const value = {
		user,
		loading,
		currentCompany,
		setUser: setUserSession,
		/**
		 * @param {Account} company
		 */
		async setCurrentCompany(company, { isPersisted = false } = {}) {
			if (isPersisted) {
				Cookies.set('currentCompanyId', String(company.companyId), {
					expires: 1,
					sameSite: 'Strict',
				})
				Cookies.set('currentCompanyIsClient', String(company.isClient), {
					expires: 1,
					sameSite: 'Strict',
				})
			}
			setCurrentCompany(company)
		},
		clearSession,
	}

	return (
		<SessionContext.Provider value={value}>{children}</SessionContext.Provider>
	)
}
