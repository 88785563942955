// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'

class EnvironmentDataService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		this.#http = http
	}

	#http

	/**
	 * @param {number} companyId
	 */
	findAll(companyId) {
		return this.#http.get(`/api/environments?companyId=${companyId}`)
	}

	/**
	 * @param {Id<'environments'>} id
	 */
	findOne(id) {
		return this.#http.get(`/api/environments/${id}`)
	}

	/**
	 * @param {Api.Environment} data
	 */
	async create(data) {
		return this.#http.post(`/api/environments`, data)
	}

	/**
	 * @param {Id<'environments'>} id
	 */
	async connect(id) {
		return this.#http.patch(`/api/environments/${id}/connect`)
	}

	/**
	 * @param {Id<'environments'>} id
	 * @param {Partial<Api.Environment>} data
	 */
	async update(id, data) {
		return this.#http.put(`/api/environments/${id}`, data)
	}

	/**
	 * @param {Id<'environments'>} id
	 */
	async remove(id) {
		return this.#http.delete(`/api/environments/${id}`)
	}

	/**
	 * @param {Id<'environments'>} environmentId
	 */
	epicorCompaniesList(environmentId) {
		return this.#http.get(`/api/environments/${environmentId}/company`)
	}
}

export function useEnvironmentDataService() {
	const http = useHttp()
	return useMemo(() => new EnvironmentDataService(http), [http])
}
