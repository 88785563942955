import { Navbar, Nav } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useImpersonation } from 'hooks/useImpersonation'
import MenuOptions from './menu.options.component'
import Logo from '../shared/logo.component'

const SideBar = () => {
	const [expanded, setExpanded] = useState(false)
	const location = useLocation()
	const { impersonatedCustomer, isImpersonationModalOpen } = useImpersonation()

	// Collapse the menu if the user has interacted with items in it such as
	// navigating to a new location or impersonating a user.
	useEffect(() => {
		setExpanded(false)
	}, [impersonatedCustomer, isImpersonationModalOpen, location])

	return (
		<Navbar
			expand="md"
			className="navbar-dark flex-md-column"
			expanded={expanded}
		>
			<Navbar.Brand className="ms-2">
				<Link to="/">
					<Logo />
				</Link>
			</Navbar.Brand>
			<Navbar.Toggle
				onClick={() => {
					setExpanded((expanded) => !expanded)
				}}
			/>
			<Navbar.Collapse>
				<Nav className="flex-md-column">
					<MenuOptions />
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}

export default SideBar
