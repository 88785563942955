import { Breadcrumb } from 'react-bootstrap'

export const HeaderBreadcrumb = ({ children }) => {
	return (
		<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
			<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
			{children}
		</Breadcrumb>
	)
}

HeaderBreadcrumb.Item = Breadcrumb.Item
