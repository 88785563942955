import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import useAuth from 'hooks/useAuth'
import PasswordField from '../shared/password.field.component'

const NewConnection = ({ onSubmit }) => {
	const { currentCompany } = useAuth()
	const [showComingSoon, setShowComingSoon] = useState(false)

	const submit = (event) => {
		event.preventDefault()
		onSubmit({
			url: event.target.url?.value,
			apiKey: event.target.apiKey?.value,
			username: event.target.username?.value,
			password: event.target.password?.value,
			localName: event.target.localName?.value,
			clientId: currentCompany.accountId,
			baseCompany: event.target.baseCompany?.value,
		})
	}
	return (
		<Form onSubmit={submit}>
			<div className="d-grid gap-4">
				<Form.Group controlId="form.url">
					<Form.Label>REST API Endpoint URL</Form.Label>
					<Form.Control
						name="url"
						type="text"
						placeholder="https://epicor.mycompany.com/EpicorTest10/api/v2"
						autoFocus
					/>
				</Form.Group>
				<Form.Group controlId="form.apiKey">
					<Form.Label>API Keys</Form.Label>
					<PasswordField name="apiKey" placeholder="Enter the API Key" />
				</Form.Group>
				<Form.Group controlId="form.username">
					<Form.Label>Username</Form.Label>
					<Form.Control
						name="username"
						type="text"
						placeholder="Epicor Username"
					/>
				</Form.Group>
				<Form.Group controlId="form.password">
					<Form.Label>Password</Form.Label>
					<PasswordField name="password" placeholder="Epicor Password" />
				</Form.Group>
				<Form.Group controlId="baseCompany">
					<Form.Label>Base Company ID</Form.Label>
					<Form.Control type="text" placeholder="EPIC03" />
				</Form.Group>
				<Form.Group controlId="localName">
					<Form.Label>Environment Title</Form.Label>
					<Form.Control type="text" placeholder="Environment title" />
				</Form.Group>

				<Button variant="primary mt-3" type="submit">
					Save New Connection
				</Button>
				<Button
					variant="light"
					onClick={() => setShowComingSoon(!showComingSoon)}
				>
					<p className="mb-0">Help Filling REST API Endpoint</p>
					{showComingSoon && <small className="text-center">Coming soon</small>}
				</Button>
			</div>
		</Form>
	)
}

export default NewConnection
