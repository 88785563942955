// @ts-check

import { Toast } from 'react-bootstrap'

/**
 * @param {object} props
 * @param {string | null} props.message
 * @param {string} [props.header]
 */
const Toaster = ({ message, header = '' }) => {
	return (
		<Toast
			style={{ position: 'absolute', right: 10, bottom: 10 }}
			show={!!message}
			delay={5000}
			className="d-inline-block m-1"
			bg="primary"
		>
			<Toast.Header>
				<img
					src="holder.js/20x20?text=%20"
					aria-hidden="true"
					className="rounded me-2"
					alt=""
				/>
				<strong className="me-auto">{header}</strong>
			</Toast.Header>
			<Toast.Body>{message}</Toast.Body>
		</Toast>
	)
}

export default Toaster
