// @ts-check

import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import {
	AuthProvider,
	RequireAdmin,
	RequireAuth,
	RequireClient,
	RequireCustomer,
} from 'hooks/useAuth'
import { ImpersonationProvider } from 'hooks/useImpersonation'
import { SessionProvider } from 'hooks/useSession'
import { SettingsProvider } from 'hooks/useSettings'
import UsersList from './components/users'
import Login from './components/login/login.component'
import Home from './components/home/home.component'
import Settings from './components/settings/settings.component'
import Tickets from './components/tickets/ticket.component'
import ResetPassword from './components/login/reset-password.component'
import NewPassword from './components/login/new-password.component'
import Main from './components/main.component'
import './App.css'
import EpicorConnections from './components/epicor'
import Orders from './components/orders'
import Quotes from './components/quotes'
import Invoices from './components/invoices'
import Payments from './components/payments'
import Shipments from './components/shipments'
import Queries from './components/queries'
import RMAs from './components/rmas'
import HelpDesks from './components/helpdesks'

export default function App() {
	return (
		<ImpersonationProvider>
			<SessionProvider>
				<AuthProvider>
					<BrowserRouter>
						<Routes>
							<Route path="/login" element={<Login />} />
							<Route path="/reset-password" element={<ResetPassword />} />
							<Route path="/signin/:accessToken" element={<NewPassword />} />

							<Route
								path="/"
								element={
									<RequireAuth>
										<SettingsProvider>
											<Main />
										</SettingsProvider>
									</RequireAuth>
								}
							>
								{/* These routes are available to all users. */}
								<Route index element={<Home />} />
								<Route path="home" element={<Navigate to="/" replace />} />

								{
									// The /payments route used to be used by customers as well so
									// we need a fallback when the user isn't a client.
								}
								<Route
									element={
										<RequireClient fallbackRoutePath="/customer/payments" />
									}
								>
									<Route path="payments" element={<Payments stripeOnly />} />
									<Route
										path="payments-stripe"
										element={<Navigate to="/payments" replace />}
									/>
								</Route>

								<Route element={<RequireClient />}>
									<Route element={<RequireAdmin />}>
										<Route
											path="users"
											element={<UsersList displayType="client" />}
										/>
										<Route path="settings" element={<Settings />} />
										<Route path="tickets" element={<Tickets />} />
										<Route
											path="epicor-connection"
											element={<EpicorConnections />}
										/>
										<Route path="queries" element={<Queries />} />
									</Route>
								</Route>

								<Route path="customer" element={<RequireCustomer />}>
									<Route path="orders" element={<Orders />} />
									<Route path="quotes" element={<Quotes />} />
									<Route path="invoices" element={<Invoices />} />
									<Route path="payments" element={<Payments />} />
									<Route path="shipments" element={<Shipments />} />
									<Route path="rmas" element={<RMAs />} />
									<Route path="helpdesks" element={<HelpDesks />} />
									<Route path="queries" element={<Queries />} />

									<Route element={<RequireAdmin />}>
										<Route
											path="users"
											element={<UsersList displayType="customer" />}
										/>
									</Route>
								</Route>

								{/* Redirect old customer routes to customer path. */}
								<Route
									path="orders"
									element={<Navigate to="/customer/orders" replace />}
								/>
								<Route
									path="quotes"
									element={<Navigate to="/customer/quotes" replace />}
								/>
								<Route
									path="invoices"
									element={<Navigate to="/customer/invoices" replace />}
								/>
								<Route
									path="shipments"
									element={<Navigate to="/customer/shipments" replace />}
								/>
								<Route
									path="rmas"
									element={<Navigate to="/customer/rmas" replace />}
								/>
								<Route
									path="helpdesks"
									element={<Navigate to="/customer/helpdesks" replace />}
								/>

								{/* 👇️ only match this when no other routes match */}
								<Route path="*" element={<Navigate to="/" />} />
							</Route>
						</Routes>
					</BrowserRouter>
				</AuthProvider>
			</SessionProvider>
		</ImpersonationProvider>
	)
}
