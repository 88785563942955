import { useState, useEffect } from 'react'
import { Typeahead, TypeaheadMenu } from 'react-bootstrap-typeahead'
import useAuth from 'hooks/useAuth'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { useCustomerService } from 'services/customer.service'

/**
 * @param {object} props
 * @param {Api.Company} [props.company]
 * @param {(customer: Api.Customer) => void} props.onChange
 */
const AsyncCustomerTypeahead = ({ company, onChange }) => {
	const { currentCompany } = useAuth()
	const CustomerService = useCustomerService()
	const [options, setOptions] = useState(
		/** @type {readonly Api.Customer[]} */ ([]),
	)
	const [customer, setCustomer] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	// Load the part results.
	useEffect(() => {
		if (customer.length < 3) {
			//Must have 3 characters or more
			setOptions([])
			return
		}

		const searchTimeout = setTimeout(async () => {
			setIsLoading(true)
			try {
				const response = await CustomerService.findAll({
					company,
					search: customer,
				})
				setOptions(response.data)
			} catch (error) {
				console.error('Error fetching results:', error)
			} finally {
				setIsLoading(false)
			}
		}, 300) // Adding a debounce to limit API calls

		return () => {
			clearTimeout(searchTimeout) // Cleanup the timeout
		}
	}, [CustomerService, company, currentCompany, customer])

	return (
		<Typeahead
			id="customer-search"
			onInputChange={setCustomer}
			onChange={([selected]) => {
				onChange(selected)
			}}
			options={options}
			isLoading={isLoading}
			labelKey="name"
			placeholder="Customer Name or CustID..."
			className="position-unset"
			renderMenu={(results, menuProps) => (
				<TypeaheadMenu
					{...menuProps}
					options={results}
					labelKey={({ custId, name }) => `${name} (${custId})`}
					className="m-2"
					style={{ left: 0, right: 0 }}
				/>
			)}
		/>
	)
}

export default AsyncCustomerTypeahead
