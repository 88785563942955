import { useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'

const StripeComponent = ({
	clientSecret,
	totalAmount,
	setStep,
	setPaymentStatus,
	numberLocale,
}) => {
	const stripe = useStripe()
	const elements = useElements()
	const [error, setError] = useState(null)
	const [isProcessing, setIsProcessing] = useState(false)

	const submitPayment = async (event) => {
		event.preventDefault()
		if (!stripe || !elements) {
			setError('Stripe has not been initialized properly.')
			return
		}

		setIsProcessing(true)
		setError(null)

		elements.submit()

		try {
			const result = await stripe.confirmPayment({
				elements,
				clientSecret,
				confirmParams: {
					return_url: window.location.href,
				},
				redirect: 'if_required',
			})

			if (result.error) {
				console.error('Payment failed:', result.error)
				setError(`Payment failed: ${result.error.message}`)
			} else if (
				result.paymentIntent?.status === 'succeeded' ||
				result.paymentIntent?.status === 'processing'
			) {
				setStep(3)
				setPaymentStatus(result.paymentIntent?.status)
			} else {
				setError('Unexpected result from payment processing.')
				console.error('Unexpected result:', result)
			}
		} catch (err) {
			console.error('Payment processing error:', err)
			setError('Payment processing error. Please try again.')
		} finally {
			setIsProcessing(false)
		}
	}

	return (
		elements &&
		stripe && (
			<>
				<Form>
					<PaymentElement />
					{error && (
						<Alert variant="danger" className="mt-2 mb-0">
							{error}
						</Alert>
					)}
				</Form>

				<Button
					variant="primary"
					className="mt-2"
					onClick={submitPayment}
					disabled={!stripe || !elements || isProcessing}
					type="submit"
				>
					{isProcessing
						? 'Processing...'
						: `Confirm Payment of ${numberLocale.format(totalAmount)}`}
				</Button>
			</>
		)
	)
}

export default StripeComponent
