// @ts-check

import { Link } from 'react-router-dom'
import {
	Bag,
	Bank,
	CardText,
	Cash,
	ClipboardData,
	Eyeglasses,
	FiletypeSql,
	Gear,
	Headset,
	House,
	People,
	Share,
	Stripe,
	Table,
	Tools,
	Truck,
} from 'react-bootstrap-icons'
import { useAuthenticated } from 'hooks/useAuth'
import { useSettings } from 'hooks/useSettings'
import { useImpersonation } from 'hooks/useImpersonation'

const Options = () => {
	const { currentCompany } = useAuthenticated()

	const roleKey =
		`${currentCompany.roleType}_${currentCompany.roleName}`.toLowerCase()
	switch (roleKey) {
		case 'customer_admin':
			return <CustomerAdminMenuOptions />
		case 'customer_ap':
		case 'customer_ar':
			return <CustomerMenuOptions />
		case 'client_ap':
		case 'client_ar':
			return <ClientMenuOptions />
		case 'client_admin':
			return <ClientAdminMenuOptions />
		case 'superadmin_superuser':
			return <ClientAdminMenuOptions />
		default:
			return <CustomerMenuOptions />
	}
}

const ClientAdminMenuOptions = () => {
	const { openImpersonationModal, impersonatedCustomer } = useImpersonation()
	return (
		<>
			<Link to="/" className="nav-link">
				<House className="d-none d-lg-inline" /> Home
			</Link>
			<Link to="/users" className="nav-link">
				<People className="d-none d-lg-inline" /> User Management
			</Link>
			<Link to="/settings" className="nav-link">
				<Gear className="d-none d-lg-inline" /> Settings
			</Link>
			<Link to="/epicor-connection" className="nav-link">
				<Share className="d-none d-lg-inline" /> Epicor connection
			</Link>
			<Link to="/queries" className="nav-link">
				<FiletypeSql className="d-none d-lg-inline" /> Custom Queries Setup
			</Link>
			<Link to="/tickets" className="nav-link">
				<Headset className="d-none d-lg-inline" /> Support Tickets
			</Link>
			<Link to="/payments" className="nav-link">
				<Stripe className="d-none d-lg-inline" /> Payments
			</Link>
			<label
				role="button"
				onClick={() => {
					openImpersonationModal()
				}}
				className="nav-link"
			>
				<Eyeglasses className="d-none d-lg-inline" /> View as Customer
			</label>
			{impersonatedCustomer && (
				<div className="px-3">
					<CustomerMenu />
				</div>
			)}
		</>
	)
}

const CustomerMenuOptions = () => {
	const { currentCompany } = useAuthenticated()
	if (currentCompany.isClient) {
		throw new Error('Expected a customer user but got a client.')
	}
	return (
		<>
			<Link to="/" className="nav-link" style={{ maxWidth: '15rem' }}>
				{currentCompany.custName}
			</Link>
			<Link to="/" className="nav-link">
				<House className="d-none d-lg-inline" /> Home
			</Link>
			<CustomerMenu />
		</>
	)
}

const CustomerAdminMenuOptions = () => {
	const { currentCompany } = useAuthenticated()
	if (currentCompany.isClient) {
		throw new Error('Expected a customer user but got a client.')
	}
	return (
		<>
			<Link to="/" className="nav-link" style={{ maxWidth: '15rem' }}>
				{currentCompany.custName}
			</Link>
			<Link to="/" className="nav-link">
				<House className="d-none d-lg-inline" /> Home
			</Link>
			<Link to="/customer/users" className="nav-link">
				<People className="d-none d-lg-inline" /> Users
			</Link>
			<CustomerMenu />
		</>
	)
}

const ClientMenuOptions = () => {
	const { openImpersonationModal } = useImpersonation()

	return (
		<>
			<Link to="/" className="nav-link">
				<House className="d-none d-lg-inline" /> Home
			</Link>
			<Link to="/payments" className="nav-link">
				<Stripe className="d-none d-lg-inline" /> Payments
			</Link>
			<label
				role="button"
				onClick={() => {
					openImpersonationModal()
				}}
				className="nav-link"
			>
				<Eyeglasses className="d-none d-lg-inline" />
				View as Customer
			</label>
			<div className="px-3">
				<CustomerMenu />
			</div>
		</>
	)
}

const CustomerMenu = () => {
	const { settings } = useSettings()

	return (
		<>
			{!settings.orderDisableMenu && (
				<Link to="/customer/orders" className="nav-link">
					<Bag className="d-none d-lg-inline" /> Orders
				</Link>
			)}
			{!settings.quoteDisableMenu && (
				<Link to="/customer/quotes" className="nav-link">
					<ClipboardData className="d-none d-lg-inline" /> Quotes
				</Link>
			)}
			{!settings.invoiceDisableMenu && (
				<Link to="/customer/invoices" className="nav-link">
					<Bank className="d-none d-lg-inline" /> Invoices
				</Link>
			)}
			{!settings.stripeDisableMenu && (
				<Link to="/customer/payments" className="nav-link">
					<Cash className="d-none d-lg-inline" /> Payments
				</Link>
			)}
			{!settings.shipmentDisableMenu && (
				<Link to="/customer/shipments" className="nav-link">
					<Truck className="d-none d-lg-inline" /> Shipments
				</Link>
			)}
			{!settings.rmaDisableMenu && (
				<Link to="/customer/rmas" className="nav-link">
					<Tools className="d-none d-lg-inline" /> RMAs
				</Link>
			)}
			{!settings.helpdeskDisableMenu && (
				<Link to="/customer/helpdesks" className="nav-link">
					<CardText className="d-none d-lg-inline" /> Cases
				</Link>
			)}
			{!settings.queryDisableMenu && (
				<Link to="/customer/queries" className="nav-link">
					<Table className="d-none d-lg-inline" /> Custom Queries
				</Link>
			)}
		</>
	)
}

const MenuOptions = () => {
	return <Options />
}

export default MenuOptions
