// @ts-check

import { Modal, Button, Row, Form } from 'react-bootstrap'
import { useState } from 'react'
import { useImpersonation } from 'hooks/useImpersonation'
import AsyncCustomerTypeahead from 'components/shared/customer.search.component'

/**
 * @param {object} props
 * @param {boolean} props.show
 * @param {() => void} props.onSelect
 * @param {() => void} [props.onClose]
 */
const ImpersonateModal = ({ show, onSelect, onClose }) => {
	const [selectedCustomer, setSelectedCustomer] = useState(
		/** @type {Api.Customer | null} */ (null),
	)
	const { setImpersonatedCustomer } = useImpersonation()
	const impersonate = () => {
		if (selectedCustomer) {
			setImpersonatedCustomer(selectedCustomer)
			onSelect()
		}
	}

	return (
		<Modal
			size="lg"
			show={show}
			animation
			contentClassName="p-3"
			onHide={onClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>View as Customer</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Row className="d-grid gap-4 mt-4">
					<Form.Group controlId="form.shipTo">
						<Form.Label>Select customer you want to impersonate</Form.Label>
						<AsyncCustomerTypeahead
							onChange={(selected) => {
								setSelectedCustomer(selected)
							}}
						/>
					</Form.Group>
					<Button
						variant="primary"
						onClick={impersonate}
						disabled={!selectedCustomer}
					>
						View as Customer
					</Button>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
				</Row>
			</Modal.Body>
		</Modal>
	)
}

export default ImpersonateModal
