// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'

class EpicorSharedService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		this.#http = http
	}

	#http

	/**
	 * @param {Id<'company'>} companyId
	 * @param {Id<'environments'>} environmentId
	 * @returns {AxiosReturn<readonly Api.ShipVia[]>}
	 */
	shipViaList(companyId, environmentId) {
		return this.#http.get(
			`/api/environments/${environmentId}/company/${companyId}/ship-via`,
		)
	}

	/**
	 * @param {Id<'environments'>} environmentId
	 * @param {Id<'company'>} companyId
	 * @param {string} partNum
	 * @returns {AxiosReturn<readonly Api.Part[]>}
	 */
	partSearch(environmentId, companyId, partNum) {
		return this.#http.get(
			`/api/environments/${environmentId}/company/${companyId}/part?partNum=${partNum}`,
		)
	}

	/**
	 * @param {Id<'company'>} companyId
	 * @param {Id<'environments'>} environmentId
	 * @param {string} partNum
	 */
	partInfo(companyId, environmentId, partNum) {
		return this.#http.get(
			`/api/environments/${environmentId}/company/${companyId}/part/${partNum}`,
		)
	}

	/**
	 * @param {Id<'customers'>} customerId
	 * @param {string} shipToNum
	 * @param {string} part
	 * @param {number} qty
	 * @param {string} uom
	 */
	itemPriceByQty(customerId, shipToNum, part, qty, uom) {
		return this.#http.post(`/api/customers/${customerId}/orders/item-price`, {
			part,
			qty,
			shipToNum,
			uom,
		})
	}
}

export function useEpicorSharedService() {
	const http = useHttp()
	return useMemo(() => new EpicorSharedService(http), [http])
}
