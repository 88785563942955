// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'
import { EpicorBaseService } from './base.service'

/**
 * @extends {EpicorBaseService<
 * 	'rmas',
 * 	Api.RMA,
 * 	unknown,
 * 	unknown,
 * 	Api.RMACreationResult
 * >}
 */
class EpicorRMAService extends EpicorBaseService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		super('rmas', http)
		this.#http = http
	}

	#http

	/**
	 * @param {Id<'environments'>} environmentId
	 * @param {Id<'company'>} companyId
	 * @returns {AxiosReturn<Api.RMAReason[]>}
	 */
	rmaReasons(environmentId, companyId) {
		return this.#http.get(
			`/api/environments/${environmentId}/company/${companyId}/rma-reasons`,
		)
	}
}

export function useEpicorRMAService() {
	const http = useHttp()
	return useMemo(() => new EpicorRMAService(http), [http])
}
