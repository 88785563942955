import { Navigate } from 'react-router-dom'
import { ListPageHeader } from 'components/shared/header/list-page-header.component'
import { useSettings } from 'hooks/useSettings'
import ShipmentList from './shipments.list.component'

const Shipments = () => {
	const { settings } = useSettings()

	if (settings.shipmentDisableMenu) {
		return <Navigate to="/" replace />
	}

	return (
		<>
			<ListPageHeader breadcrumbs="Shipments" />
			<ShipmentList />
		</>
	)
}

export default Shipments
