import { useEffect, useState } from 'react'
import { Button, Alert } from 'react-bootstrap'
import { ListPageHeader } from 'components/shared/header/list-page-header.component'
import LoadingParagraphs from 'components/shared/loading/loading.paragraph.component'
import { useRefresher } from 'hooks/useRefresher'
import { useSettings } from 'hooks/useSettings'
import { useTicketService } from 'services/ticket.service'
import TicketForm from './ticket.form.component'
import TicketTable from './ticket.table.component'

const Tickets = () => {
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [showForm, setShowForm] = useState(false)
	const [tickets, setTickets] = useState(null)
	const refresher = useRefresher()
	const TicketService = useTicketService()
	const { settings } = useSettings()

	useEffect(() => {
		setLoading(true)
		TicketService.findAll()
			.then((response) => {
				setTickets(response.data)
			})
			.catch((error) => {
				setError(error)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [TicketService])

	return (
		<>
			<ListPageHeader
				breadcrumbs="Tickets"
				action={
					!settings.ticketDisableCreate && (
						<Button
							onClick={() => {
								setShowForm(true)
							}}
						>
							Create New Support Ticket
						</Button>
					)
				}
			/>
			{error ? (
				<Alert dismissible variant="danger" onClose={() => setError(null)}>
					{error.toString()}
				</Alert>
			) : null}
			{loading || !tickets ? (
				<LoadingParagraphs cant={5} animation="glow" />
			) : (
				<TicketTable tickets={tickets} />
			)}
			<TicketForm
				show={showForm}
				onClose={() => setShowForm(false)}
				onOperationCompleted={refresher.refresh}
			/>
		</>
	)
}
export default Tickets
