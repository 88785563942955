import { Modal } from 'react-bootstrap'
import NewRMA from './new.rma.component'

const RMAForm = ({ show = false, onClose, onOperationCompleted }) => {
	const notifyCreated = (data) => {
		onOperationCompleted?.(
			`A new RMA has been added. RMA number: ${data.rmaNum}`,
		)
		onClose?.()
	}

	return (
		<Modal size={'xl'} show={show} onHide={onClose} contentClassName="p-3">
			<Modal.Header closeButton>
				<Modal.Title>Create RMA</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<NewRMA onSubmitted={notifyCreated} />
			</Modal.Body>
		</Modal>
	)
}

export default RMAForm
