// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'
import { useSession } from 'hooks/useSession'

class UserDataService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 * @param {Id<'environments'>} environmentId
	 */
	constructor(http, environmentId) {
		this.#http = http
		this.#environmentId = environmentId
	}

	#http
	#environmentId

	/**
	 * @returns {AxiosReturn<readonly Api.User[]>}
	 */
	getAll() {
		return this.#http.get(`/api/environments/${this.#environmentId}/users`)
	}

	/**
	 * @param {Id<'customers'>} customerId
	 * @returns {AxiosReturn<readonly Api.User[]>}
	 */
	getAllForCustomer(customerId) {
		return this.#http.get(`/api/customers/${customerId}/users`)
	}

	/**
	 * @param {Id<'company'>} companyId
	 */
	async getUsersByCompanyId(companyId) {
		const resp = await this.#http.get(
			`/api/environments/${this.#environmentId}/users?companyId=${companyId}`,
		)
		return resp.data
	}

	/**
	 * @returns {Promise<readonly Api.Company[]>}
	 */
	async getAllCompanies() {
		const resp = await this.#http.get(
			`/api/environments/${this.#environmentId}/company`,
		)
		return resp.data
	}

	/**
	 * @param {string} roleType
	 * @returns {Promise<readonly Api.Role[]>}
	 */
	async getRolesByType(roleType) {
		const resp = await this.#http.get(
			`/api/environments/${this.#environmentId}/users/roles/${roleType}`,
		)
		return resp.data
	}

	/**
	 * @param {Id<'users'>} id
	 * @param {Id<'customers'> | undefined} [customerId]
	 */
	get(id, customerId) {
		const url = customerId
			? `/api/customers/${customerId}/users/${id}`
			: `/api/environments/${this.#environmentId}/users/${id}`
		return this.#http.get(url)
	}

	/**
	 * @param {Api.UserCreationData} data
	 */
	invite({ customerId, ...data }) {
		const url = customerId
			? `/api/customers/${customerId}/users/invite`
			: `/api/environments/${this.#environmentId}/users/invite`
		return this.#http.post(url, data)
	}

	/**
	 * @param {Id<'users'>} id
	 * @param {unknown} data
	 * @param {Id<'customers'> | undefined} [customerId]
	 */
	update(id, data, customerId) {
		const url = customerId
			? `/api/customers/${customerId}/users/${id}`
			: `/api/environments/${this.#environmentId}/users/${id}`
		return this.#http.put(url, data)
	}

	/**
	 * @param {Id<'users'>} id
	 * @param {Id<'customers'> | undefined} [customerId]
	 */
	delete(id, customerId) {
		const url = customerId
			? `/api/customers/${customerId}/users/${id}`
			: `/api/environments/${this.#environmentId}/users/${id}`
		return this.#http.delete(url)
	}

	/**
	 * @param {string} email
	 */
	findByEmail(email) {
		return this.#http.get(
			`/api/environments/${this.#environmentId}/users?email=${email}`,
		)
	}

	/**
	 * @param {Id<'company'>} companyId
	 * @param {Id<'customers'> | undefined} [customerId]
	 */
	importContacts(companyId, customerId) {
		let url = `/api/environments/${this.#environmentId}/company/${companyId}`
		if (customerId !== undefined) {
			url += `/customers/${customerId}`
		}
		return this.#http.post(`${url}/contacts/import`)
	}

	/**
	 * @param {Id<'company'>} companyId
	 * @param {Id<'customers'> | undefined} [customerId]
	 */
	activateContacts(companyId, customerId) {
		let url = `/api/environments/${this.#environmentId}/company/${companyId}`
		if (customerId !== undefined) {
			url += `/customers/${customerId}`
		}
		return this.#http.patch(`${url}/contacts`, { active: true })
	}

	/**
	 * @param {Id<'company'>} companyId
	 * @param {unknown[]} data
	 */
	bulkUpload(companyId, data) {
		return this.#http.post(
			`/api/environments/${this.#environmentId}/company/${companyId}/contacts/upload`,
			data,
		)
	}
}

export function useUserDataService() {
	const http = useHttp()
	const { currentCompany } = useSession()
	return useMemo(() => {
		if (currentCompany) {
			return new UserDataService(http, currentCompany.environmentId)
		} else {
			return null
		}
	}, [currentCompany, http])
}
