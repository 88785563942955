import { useState } from 'react'
import { Button, Pagination } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'
import { ListPageHeader } from 'components/shared/header/list-page-header.component'
import { useRefresher } from 'hooks/useRefresher'
import { useSettings } from 'hooks/useSettings'
import RMAForm from './rma.form.component'
import RMAList from './rma.list.component'

const RMAs = () => {
	const [showForm, setShowForm] = useState(false)
	const refresher = useRefresher()
	const { settings } = useSettings()

	if (settings.rmaDisableMenu) {
		return <Navigate to="/" replace />
	}

	return (
		<>
			<ListPageHeader
				breadcrumbs="RMAs"
				action={
					!settings.rmaDisableCreate && (
						<Button
							onClick={() => {
								setShowForm(true)
							}}
						>
							Create New RMA
						</Button>
					)
				}
				pagination={
					false && (
						<Pagination className="m-0">
							<Pagination.First disabled />
							<Pagination.Prev disabled />
							<Pagination.Item>{1}</Pagination.Item>
							<Pagination.Item disabled>{2}</Pagination.Item>
							<Pagination.Item disabled>{3}</Pagination.Item>
							<Pagination.Ellipsis disabled />
							<Pagination.Next disabled />
							<Pagination.Last disabled />
						</Pagination>
					)
				}
			/>
			<RMAList refresher={refresher} />
			<RMAForm
				show={showForm}
				onClose={() => setShowForm(false)}
				onOperationCompleted={refresher.refresh}
			/>
		</>
	)
}

export default RMAs
