// @ts-check

import { useState } from 'react'
import {
	Button,
	Dropdown,
	DropdownButton,
	FormControl,
	InputGroup,
	Tab,
	Tabs,
} from 'react-bootstrap'
import { Search, XLg } from 'react-bootstrap-icons'
import UserForm from './user.form.component'
import ContactForm from './contact.form.component'
import BulkUploadForm from './bulkupload.form.component'
import UsersList, { userTypes } from './users.list.component'
import { ListPageHeader } from 'components/shared/header/list-page-header.component'
import { includes } from 'components/utilities'
import { useRefresher } from 'hooks/useRefresher'
import { useAuthenticated } from 'hooks/useAuth'

/** @import { UserType } from './users.list.component' */

/**
 * @param {object} props
 * @param {(action: string) => void} props.onSearchChange
 * @param {string} props.searchText
 */
const SearchComponent = ({ onSearchChange, searchText }) => {
	return (
		<InputGroup style={{ maxWidth: '250px' }}>
			<FormControl
				type="text"
				placeholder="Search..."
				value={searchText}
				onChange={(event) => {
					onSearchChange(event.target.value)
				}}
			/>
			{searchText ? (
				<InputGroup.Text
					onClick={() => {
						onSearchChange('')
					}}
					style={{ cursor: 'pointer' }}
				>
					<XLg />
				</InputGroup.Text>
			) : (
				<InputGroup.Text>
					<Search />
				</InputGroup.Text>
			)}
		</InputGroup>
	)
}

/**
 * @param {object} props
 * @param {'client' | 'customer'} props.displayType
 */
const Users = ({ displayType }) => {
	const [selectedUserType, setSelectedUserType] = useState(
		/** @type {UserType} */ (/** @satisfies {UserType} */ (displayType)),
	)
	const { currentCompany } = useAuthenticated()
	const [activeModal, setActiveModal] = useState(
		/** @type {Action | null} */ (null),
	)
	const [userData, setUserData] = useState(
		/** @type {Api.User | null} */ (null),
	)
	const [searchValue, setSearchValue] = useState('')
	const refresher = useRefresher()
	const isClient = currentCompany.isClient

	/**
	 * @param {Api.User} user
	 */
	const showEditForm = (user) => {
		setUserData(user)
		if (user !== null) {
			setActiveModal('edit-user')
		}
	}

	return (
		<>
			<ListPageHeader
				breadcrumbs="User Management"
				action={
					<>
						{isClient ? (
							<DropdownButton title="User Actions">
								<Dropdown.Item
									onClick={() => {
										setActiveModal('create-user')
									}}
								>
									Add New User
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setActiveModal('import-contacts')
									}}
								>
									Import Contacts
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setActiveModal('activate-contacts')
									}}
								>
									Activate Contacts
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setActiveModal('bulk-upload')
									}}
								>
									Bulk Upload
								</Dropdown.Item>
							</DropdownButton>
						) : (
							<Button
								onClick={() => {
									setActiveModal('create-user')
								}}
							>
								Add New User
							</Button>
						)}
						<SearchComponent
							searchText={searchValue}
							onSearchChange={setSearchValue}
						/>
					</>
				}
			/>
			{displayType === 'client' && (
				<Tabs
					activeKey={selectedUserType}
					onSelect={(key) => {
						if (!key) {
							return
						}
						if (!includes(userTypes, key)) {
							throw new Error(`Unexpected user type from tab: ${key}`)
						}
						setSelectedUserType(key)
					}}
					className="mb-3"
				>
					<Tab key="client" eventKey="client" title="Client User" />
					{false && ( //Future Use
						<Tab key="salesrep" eventKey="salesrep" title="Client Sales Rep" />
					)}
					<Tab key="customer" eventKey="customer" title="Customer" />
					{false && ( //Future Use
						<Tab key="supplier" eventKey="supplier" title="Supplier" disabled />
					)}
				</Tabs>
			)}
			<UsersList
				userType={selectedUserType}
				onEditUser={showEditForm}
				refresher={refresher}
				searchValue={searchValue}
				displayType={displayType}
			/>
			<UserForm
				show={activeModal === 'create-user' || activeModal === 'edit-user'}
				mode={
					activeModal === 'create-user'
						? 'create'
						: activeModal === 'edit-user'
							? 'edit'
							: undefined
				}
				onClose={() => {
					setActiveModal(null)
				}}
				onOperationCompleted={refresher.refresh}
				userData={userData}
			/>
			{isClient && (
				<>
					<ContactForm
						show={
							activeModal === 'activate-contacts' ||
							activeModal === 'import-contacts'
						}
						mode={
							activeModal === 'activate-contacts'
								? 'activate'
								: activeModal === 'import-contacts'
									? 'import'
									: undefined
						}
						onClose={() => {
							setActiveModal(null)
						}}
						onOperationCompleted={refresher.refresh}
					/>
					<BulkUploadForm
						show={activeModal === 'bulk-upload'}
						onClose={() => {
							setActiveModal(null)
						}}
						onOperationCompleted={refresher.refresh}
					/>
				</>
			)}
			<refresher.Toaster header="User" />
		</>
	)
}

/**
 * @typedef {'create-user'
 * 	| 'edit-user'
 * 	| 'import-contacts'
 * 	| 'activate-contacts'
 * 	| 'bulk-upload'
 * } Action
 */

export default Users
