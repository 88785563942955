// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'
import { EpicorBaseService } from './base.service'

/**
 * @extends EpicorBaseService<'shipments'>
 */
class EpicorShipmentService extends EpicorBaseService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		super('shipments', http)
		this.#http = http
	}

	#http

	/**
	 * @param {Id<'customers'>} customerId
	 * @param {Id<'shipments'>} packNum
	 * @param {string} bolNum
	 * @returns {AxiosReturn<Blob>}
	 */
	printBOL(customerId, packNum, bolNum) {
		return this.#http.get(
			`/api/customers/${customerId}/shipments/${packNum}/printBOL/${bolNum}`,
			{ responseType: 'blob' },
		)
	}
}

export function useEpicorShipmentService() {
	const http = useHttp()
	return useMemo(() => new EpicorShipmentService(http), [http])
}
