// @ts-check

import { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'
import useAuth from 'hooks/useAuth'
import { useIsAutoFilled } from 'hooks/useIsAutoFilled'
import CompanyChooser from './company-chooser.component'
import PasswordField from '../shared/password.field.component'
import Logo from '../shared/logo.component'
import { getErrorMessage } from 'components/utilities'
import { AuthenticationFormContainer } from 'components/shared/authentication-form-container.component'

const LoginForm = () => {
	const { signin } = useAuth()

	const [isLoading, setIsLoading] = useState(false)
	const [isValidated, setIsValidated] = useState(false)
	const [errorMessage, setErrorMessage] = useState(
		/** @type {string | null} */ (null),
	)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const emailAutoFill = useIsAutoFilled()
	const passwordAutoFill = useIsAutoFilled()

	/** @param {import('react').FormEvent<HTMLFormElement>} event */
	const logIn = async (event) => {
		event.preventDefault()
		setIsValidated(true)
		if (!event.currentTarget.checkValidity()) {
			return
		}
		try {
			setErrorMessage(null)
			setIsLoading(true)
			await signin(email, password)
		} catch (error) {
			setErrorMessage(getErrorMessage(error))
		} finally {
			setIsLoading(false)
		}
	}

	const canSubmit =
		!isLoading &&
		(email !== '' || emailAutoFill.isAutoFilled) &&
		(password !== '' || passwordAutoFill.isAutoFilled)

	return (
		<>
			<Form
				autoComplete="on"
				onSubmit={logIn}
				className="d-grid gap-4"
				noValidate
				validated={isValidated}
			>
				<Form.Group controlId="email">
					<Form.Control
						ref={emailAutoFill.ref}
						required
						size="lg"
						type="email"
						placeholder="Email"
						value={email}
						onChange={(event) => {
							setEmail(event.target.value)
						}}
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a valid email address
					</Form.Control.Feedback>
				</Form.Group>
				<PasswordField
					ref={passwordAutoFill.ref}
					name="password"
					placeholder="Password"
					value={password}
					onChange={(event) => {
						setPassword(event.target.value)
					}}
				/>
				<Button
					size="lg"
					variant={canSubmit ? 'primary' : 'secondary'}
					type="submit"
					disabled={!canSubmit}
				>
					Login
				</Button>
			</Form>
			{errorMessage && (
				<Alert variant="danger" className="m-0">
					{errorMessage}
				</Alert>
			)}
			<Link to="/reset-password" className="link-dark text-decoration-none">
				Forgot password?
			</Link>
		</>
	)
}

const Login = () => {
	const { currentCompany, user, loading } = useAuth()

	return (
		<AuthenticationFormContainer>
			<Logo fullSize />
			{loading ? (
				<div className="w-100">
					<Spinner />
				</div>
			) : !user ? (
				<LoginForm />
			) : !currentCompany ? (
				<CompanyChooser user={user} />
			) : (
				<Navigate to="/" />
			)}
		</AuthenticationFormContainer>
	)
}

export default Login
