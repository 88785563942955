// @ts-check

import { Fragment, useEffect, useState } from 'react'
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	Row,
	Spinner,
	Table,
} from 'react-bootstrap'
import _ from 'lodash'
import { useAuthenticated, useCustomerId } from 'hooks/useAuth'
import { useSettings } from 'hooks/useSettings'
import { useEpicorInvoiceService, useEpicorRMAService } from 'services/epicor'
import { getErrorMessage } from 'components/utilities'

/**
 * @param {object} props
 * @param {(data: { rmaNum: number }) => void} props.onSubmitted
 */
const NewRMA = ({ onSubmitted }) => {
	const { currentCompany } = useAuthenticated()
	const { settings } = useSettings()
	const [error, setError] = useState(/** @type {string | null} */ (null))
	const [reasonCodes, setReasonCodes] = useState(
		/** @type {readonly Api.RMAReason[]} */ ([]),
	)
	const [selectedInvoice, setSelectedInvoice] = useState(
		/** @type {Api.Invoice | undefined} */ (undefined),
	)
	const [invoices, setInvoices] = useState(
		/** @type {readonly Api.Invoice[]} */ ([]),
	)
	const [invoiceLines, setInvoiceLines] = useState(
		/** @type {readonly Line[] | null} */ (null),
	)
	const [invoicesLoading, setInvoicesLoading] = useState(false)
	const [savingRMA, setSavingRMA] = useState(false)
	const [comments, setComments] = useState('')
	const EpicorInvoiceService = useEpicorInvoiceService()
	const EpicorRMAService = useEpicorRMAService()

	const customerId = useCustomerId()

	useEffect(() => {
		setError(null)
		setInvoiceLines(null)

		if (!selectedInvoice) {
			return
		}

		const fetchLines = async () => {
			try {
				const response = await EpicorInvoiceService.getLines(
					customerId,
					selectedInvoice.invoiceNum,
				)
				const transformedLines = response.data.map((line) => ({
					invoiceLine: line.invoiceLine,
					partNum: line.partNum,
					lineDesc: line.lineDesc,
					ourShipQty: line.ourShipQty,
					rmaQty: 0,
					salesUM: line.salesUM,
					reasonCode: '',
				}))
				setInvoiceLines(transformedLines)
			} catch (error) {
				setError(getErrorMessage(error))
			}
		}
		fetchLines()
	}, [selectedInvoice, customerId, EpicorInvoiceService])

	useEffect(() => {
		const fetchReasons = async () => {
			try {
				const response = await EpicorRMAService.rmaReasons(
					currentCompany.environmentId,
					currentCompany.companyId,
				)
				setReasonCodes(response.data)
			} catch (error) {
				setError(getErrorMessage(error))
			}
		}
		fetchReasons()
	}, [EpicorRMAService, currentCompany])

	/**
	 * @param {Line} lineItem
	 * @param {Partial<Line>} fields
	 */
	const updateLineItem = (lineItem, fields) => {
		setInvoiceLines((items) => {
			if (!items) return items
			const index = items.findIndex(
				(item) => item.invoiceLine === lineItem.invoiceLine,
			)
			if (index === -1) return items
			const newLineItems = [...items]
			newLineItems[index] = { ...items[index], ...fields }
			return newLineItems
		})
	}

	/** @param {React.FormEvent<HTMLFormElement>} event */
	const submit = async (event) => {
		event.preventDefault()
		setSavingRMA(true)

		try {
			const response = await EpicorRMAService.create(
				{
					rma: {
						invoiceNum: selectedInvoice?.invoiceNum ?? null,
						comments,
						rmaLines:
							invoiceLines
								?.filter((item) => item.rmaQty !== 0)
								.map((item) => ({
									invoiceLine: item.invoiceLine,
									partNum: item.partNum,
									description: item.lineDesc,
									qty: item.rmaQty,
									uom: item.salesUM,
									reasonCode: item.reasonCode,
								})) ?? [],
					},
				},
				customerId,
			)
			if ('error' in response.data) {
				const { error } = response.data
				/** @type {unknown} */
				const errorText = _.get(error, '[0].ErrorText')
				setError(
					typeof errorText === 'string' ? errorText : JSON.stringify(error),
				)
			} else {
				onSubmitted(response.data)
			}
		} catch (error) {
			setError(getErrorMessage(error))
		} finally {
			setSavingRMA(false)
		}
	}

	useEffect(() => {
		const loadInvoices = async () => {
			setInvoicesLoading(true)
			try {
				const response = await EpicorInvoiceService.findAll(customerId)
				const filteredData = response.data
					.filter((invoice) => !invoice.creditMemo)
					.sort((a, b) => b.invoiceNum - a.invoiceNum)
				setInvoices(filteredData)
			} catch (error) {
				setError(getErrorMessage(error))
			} finally {
				setInvoicesLoading(false)
			}
		}
		loadInvoices()
	}, [EpicorInvoiceService, customerId])

	return (
		<Form onSubmit={submit}>
			{error && (
				<Alert onClose={() => setError(null)} variant="danger" dismissible>
					{error}
				</Alert>
			)}
			{settings.rmaNewMessage && (
				<Alert variant="warning mt-3">{settings.rmaNewMessage}</Alert>
			)}
			<Container fluid>
				<Row>
					<Col xs={12} lg={6}>
						<Form.Group controlId="form.invoiceNum">
							<Form.Label>Invoice</Form.Label>
							<Form.Select
								name="invoice"
								onChange={(event) => {
									const invoiceId = Number(event.target.value) || null
									const invoice = invoices.find(
										(invoice) => invoice.invoiceNum === invoiceId,
									)
									setSelectedInvoice(invoice)
								}}
								value={selectedInvoice?.invoiceNum ?? 0}
								disabled={invoicesLoading}
							>
								{invoicesLoading ? (
									<option value="loading">Loading...</option>
								) : (
									<>
										<option value="0" disabled hidden>
											Select One...
										</option>
										{invoices.map((invoice) => (
											<option
												key={invoice.invoiceNum}
												value={invoice.invoiceNum}
											>
												{invoice.invoiceNum}
											</option>
										))}
									</>
								)}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="form.comment">
							<Form.Label>Comments</Form.Label>
							<Form.Control
								as="textarea"
								rows={3}
								name="comments"
								placeholder="Please, put your comments here."
								value={comments}
								onChange={(e) => setComments(e.target.value)}
							/>
						</Form.Group>
					</Col>
					<Col xs={12} lg={6}>
						{!selectedInvoice ? (
							<div
								className="justify-content-center align-items-center d-none d-lg-flex"
								style={{ height: '100%' }}
							>
								Select an Invoice
							</div>
						) : !invoiceLines ? (
							<div
								className="d-flex justify-content-center align-items-center"
								style={{ height: '100%', minHeight: '5rem' }}
							>
								<Spinner />
							</div>
						) : (
							<>
								<Table>
									<thead>
										<tr>
											<th>Part</th>
											<th>Qty</th>
											<th>Invoiced</th>
										</tr>
									</thead>
									<tbody>
										{invoiceLines.map((lineItem) => (
											<Fragment key={lineItem.invoiceLine}>
												<tr className="position-relative">
													<td className="border-bottom-0">
														{lineItem.partNum}
													</td>
													<td className="border-bottom-0">
														<Form.Group>
															<Form.Control
																name="qty"
																type="number"
																min={0}
																max={lineItem.ourShipQty}
																value={lineItem.rmaQty}
																onChange={(e) => {
																	const value = parseInt(e.target.value, 10)
																	updateLineItem(lineItem, {
																		rmaQty: Number.isNaN(value)
																			? 0
																			: Math.min(value, lineItem.ourShipQty),
																	})
																}}
															/>
														</Form.Group>
													</td>
													<td className="border-bottom-0">
														{lineItem.ourShipQty} {lineItem.salesUM}
													</td>
												</tr>
												<tr>
													<td colSpan={3} className="pt-0 border-bottom-0">
														<div className="line-item-description-form-control">
															{lineItem.lineDesc || '\u00A0'}
														</div>
													</td>
												</tr>
												<tr>
													<td>Reason:</td>
													<td colSpan={2} className="pt-0">
														<Form.Select
															name="reasonCode"
															value={lineItem.reasonCode}
															onChange={(e) => {
																updateLineItem(lineItem, {
																	reasonCode: e.target.value,
																})
															}}
														>
															<option value="" disabled hidden />
															{reasonCodes.map((reason) => (
																<option
																	key={reason.reasonCode}
																	value={reason.reasonCode}
																>
																	{reason.description}
																</option>
															))}
														</Form.Select>
													</td>
												</tr>
											</Fragment>
										))}
									</tbody>
								</Table>
								<Button
									variant="primary mt-auto"
									type="submit"
									disabled={savingRMA}
									className="mb-3"
								>
									{savingRMA ? (
										<Spinner animation="border" role="status">
											<span className="visually-hidden">
												Saving RMA, please wait...
											</span>
										</Spinner>
									) : (
										'Send RMA'
									)}
								</Button>
							</>
						)}
					</Col>
				</Row>
			</Container>
		</Form>
	)
}

export default NewRMA

/** @typedef {{
 * 	invoiceLine: number
 * 	partNum: string
 * 	lineDesc: string
 * 	ourShipQty: number
 * 	rmaQty: number
 * 	salesUM: string
 * 	reasonCode: string
 * }} Line */
