// @ts-check

/**
 * @template {string} ResourceName
 * @template [Resource=unknown]
 * @template [ResourceLine=unknown]
 * @template [CreationData=unknown]
 * @template [CreationResult=unknown]
 */
export class EpicorBaseService {
	/**
	 * @param {ResourceName} resource
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(resource, http) {
		this.#resource = resource
		this.#http = http
	}

	#resource
	#http

	/**
	 * @param {Id<'customers'>} customerId
	 * @returns {AxiosReturn<readonly Resource[]>}
	 */
	findAll(customerId) {
		return this.#http.get(`/api/customers/${customerId}/${this.#resource}`)
	}

	/**
	 * @param {CreationData} data
	 * @param {Id<'customers'>} customerId
	 * @returns {AxiosReturn<CreationResult>}
	 */
	create(data, customerId) {
		return this.#http.post(`/api/customers/${customerId}/${this.#resource}`, {
			data,
		})
	}

	/**
	 * @param {Id<'customers'>} customerId
	 * @param {Id<ResourceName>} resourceId
	 * @returns {AxiosReturn<readonly ResourceLine[]>}
	 */
	getLines(customerId, resourceId) {
		return this.#http.get(
			`/api/customers/${customerId}/${this.#resource}/${resourceId}/lines`,
		)
	}

	/**
	 * @param {Id<'customers'>} customerId
	 * @param {Id<ResourceName>} resourceId
	 * @returns {AxiosReturn<Blob>}
	 */
	getAttachments(customerId, resourceId) {
		return this.#http.get(
			`/api/customers/${customerId}/${this.#resource}/${resourceId}/attachments`,
			{ responseType: 'blob' },
		)
	}

	/**
	 * @param {Id<'customers'>} customerId
	 * @param {Id<ResourceName>} resourceId
	 * @returns {AxiosReturn<Blob>}
	 */
	print(customerId, resourceId) {
		return this.#http.get(
			`/api/customers/${customerId}/${this.#resource}/${resourceId}/print`,
			{ responseType: 'blob' },
		)
	}
}
