// @ts-check

import { useCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { downloadFile, getErrorMessage } from 'components/utilities'
import { useEpicorInvoiceService } from 'services/epicor'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import InvoicesTable from './invoices.table.component'
import InvoicePaymentModal from './invoice.payment.component'

const InvoicesList = () => {
	const [invoices, setInvoices] = useState(
		/** @type {readonly Api.Invoice[]} */ ([]),
	)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(/** @type {string | null} */ (null))
	const [currentInvoice, setCurrentInvoice] = useState(
		/** @type {Api.Invoice | null} */ (null),
	)
	const customerId = useCustomerId()

	const EpicorInvoiceService = useEpicorInvoiceService()

	const fetchInvoices = useCallback(async () => {
		setLoading(true)
		try {
			/** Replace with proper attribute here */
			const response = await EpicorInvoiceService.findAll(customerId)
			setInvoices(response.data)
		} catch (error) {
			setError(getErrorMessage(error))
		} finally {
			setLoading(false)
		}
	}, [EpicorInvoiceService, customerId])

	/** @param {Id<'invoices'>} invoiceNum */
	const printInvoice = async (invoiceNum) => {
		setError(null)
		try {
			const response = await EpicorInvoiceService.print(customerId, invoiceNum)
			downloadFile(response)
		} catch (error) {
			setError(getErrorMessage(error))
		}
	}

	/** @param {Id<'invoices'>} invoiceNum */
	const downloadAttachments = async (invoiceNum) => {
		setError(null)

		try {
			const response = await EpicorInvoiceService.getAttachments(
				customerId,
				invoiceNum,
			)
			downloadFile(response)
		} catch (error) {
			setError(getErrorMessage(error))
		}
	}

	useEffect(() => {
		fetchInvoices()
	}, [fetchInvoices])

	return (
		<>
			{error ? <Alert variant="danger">{error}</Alert> : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<InvoicesTable
					invoices={invoices}
					onPrintInvoice={printInvoice}
					onDownloadAttachments={downloadAttachments}
					onPayInvoice={setCurrentInvoice}
				/>
			)}
			{currentInvoice && (
				<InvoicePaymentModal
					customerId={customerId}
					invoice={currentInvoice}
					onClose={() => {
						setCurrentInvoice(null)
					}}
				/>
			)}
		</>
	)
}

export default InvoicesList
