import { Dropdown } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'

export const accessors = (companyRole) => [
	{
		Header: 'Custom Queries',
		columns: [
			{
				Header: 'Query ID',
				accessor: 'queryID',
			},
			{
				Header: 'Description',
				accessor: 'queryName',
			},
			{
				Header: 'Update',
				accessor: (item) => (item.allowUpdate ? '✓' : ''),
				className: 'text-center',
			},
			{
				Header: '⚡',
				accessor: (query) => query,
				Cell: ({
					value: query,
					onDownloadQuery,
					onEditQuery,
					onDeleteQuery,
					onRunQuery,
				}) => (
					<Dropdown>
						<Dropdown.Toggle
							as={CustomToggle}
							id="dropdown-custom-components"
						/>
						<Dropdown.Menu as={CustomMenu}>
							<Dropdown.Item
								eventKey="1"
								onClick={() => {
									onDownloadQuery(query.id, query.queryID, 'csv')
								}}
							>
								Download CSV
							</Dropdown.Item>
							<Dropdown.Item
								eventKey="2"
								onClick={() => {
									onDownloadQuery(query.id, query.queryID, 'json')
								}}
							>
								Download JSON
							</Dropdown.Item>
							<Dropdown.Item
								eventKey="3"
								onClick={() => {
									onDownloadQuery(query.id, query.queryID, 'xml')
								}}
							>
								Download XML
							</Dropdown.Item>
							<Dropdown.Item eventKey="4" onClick={() => onRunQuery(query)}>
								Run Query
							</Dropdown.Item>
							{companyRole?.toLowerCase() === 'admin' ? (
								<Dropdown.Item
									eventKey="2"
									onClick={() => {
										onEditQuery(query)
									}}
								>
									Edit Query
								</Dropdown.Item>
							) : (
								''
							)}
							{companyRole?.toLowerCase() === 'admin' ? (
								<Dropdown.Item
									eventKey="3"
									onClick={() => {
										onDeleteQuery(query)
									}}
								>
									<em className="text-danger">Delete Query</em>
								</Dropdown.Item>
							) : (
								''
							)}
						</Dropdown.Menu>
					</Dropdown>
				),
			},
		],
	},
]
