// @ts-check

import { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { useUserDataService } from '../../services/user.service'
import { useAuthenticated, usePossibleCustomerId } from 'hooks/useAuth'
import AsyncCustomerTypeahead from 'components/shared/customer.search.component'
import {
	assertExhaustiveCheck,
	getErrorMessage,
	includes,
} from 'components/utilities'
import { userTypes } from './users.list.component'

/** @import {UserType} from './users.list.component' */

/**
 * @param {object} props
 * @param {() => void} props.onClose
 * @param {(newUser: Api.UserCreationData) => void} props.onSubmit
 */
const NewUser = ({ onClose, onSubmit }) => {
	const inputRef = useRef(null)
	const { currentCompany } = useAuthenticated()
	const currentCustomerId = usePossibleCustomerId()
	const [error, setError] = useState(/** @type {string | null} */ (null))
	const [selectedCompany, setSelectedCompany] = useState(
		/** @type {Api.Company | undefined} */ (undefined),
	)
	const [userType, setUserType] = useState(
		currentCustomerId
			? /** @type {UserType} */ (/** @satisfies {UserType} */ ('customer'))
			: undefined,
	)
	const [companies, setCompanies] = useState(
		/** @type {readonly Api.Company[]} */ ([]),
	)
	const [customerRoles, setCustomerRoles] = useState(
		/** @type {readonly Api.Role[]} */ ([]),
	)
	const [clientRoles, setClientRoles] = useState(
		/** @type {readonly Api.Role[]} */ ([]),
	)
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [customerId, setCustomerId] = useState(currentCustomerId)
	const [selectedRole, setSelectedRole] = useState(
		/** @type {Api.Role | undefined} */ (undefined),
	)

	const UserDataService = useUserDataService()

	useEffect(() => {
		setError(null)
		UserDataService?.getAllCompanies()
			.then((companyData) => {
				setCompanies(companyData)
				setSelectedCompany(
					companyData.length === 1 ? companyData[0] : undefined,
				)
			})
			.catch((error) => {
				setError(getErrorMessage(error))
			})
	}, [UserDataService])

	useEffect(() => {
		switch (userType) {
			case undefined:
				break
			case 'client':
				UserDataService?.getRolesByType('Client').then(setClientRoles)
				break
			case 'customer':
				UserDataService?.getRolesByType('Customer').then(setCustomerRoles)
				break
			case 'salesrep':
			case 'supplier':
				throw new Error(`Not yet implemented.`)
			default:
				assertExhaustiveCheck(userType)
		}
	}, [UserDataService, userType])

	const roles = userType === 'customer' ? customerRoles : clientRoles

	/** @param {import('react').FormEvent<HTMLFormElement>} event */
	const submit = (event) => {
		event.preventDefault()

		if (!userType) {
			return
		}

		onSubmit({
			firstName,
			lastName,
			email,
			userType,
			customerId,
			roleId: selectedRole?.id,
			companyId: selectedCompany?.id ?? currentCompany.companyId,
		})
	}

	return (
		<Form onSubmit={submit}>
			<div className="d-grid gap-4">
				{error && <Alert variant="danger">{error}</Alert>}
				<Form.Group className="required" controlId="new-user-form-first-name">
					<Form.Label>First Name</Form.Label>
					<Form.Control
						isValid={firstName.length > 2}
						required
						type="text"
						placeholder="Enter First Name"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
					/>
					<Form.Text className="text-muted">
						Do <strong>not</strong> add any text other than the person's
						preferred name.
					</Form.Text>
				</Form.Group>
				<Form.Group className="required" controlId="new-user-form-last-name">
					<Form.Label>Last Name</Form.Label>
					<Form.Control
						isValid={lastName.length > 2}
						required
						type="text"
						placeholder="Enter Last Name"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
					/>
				</Form.Group>
				<Form.Group className="required" controlId="new-user-form-email">
					<Form.Label>Email</Form.Label>
					<Form.Control
						isValid={
							email.length > 6 && email.includes('@') && email.includes('.') //TODO: Add a better email validation
						}
						required
						name="email"
						placeholder="Enter email"
						type="email"
						className="form-control"
						ref={inputRef}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</Form.Group>
				{companies.length > 1 && (
					<Form.Group
						className="required"
						controlId="new-user-form-company-name"
					>
						<Form.Label>Company name</Form.Label>
						<Form.Select
							required
							value={selectedCompany?.id ?? ''}
							onChange={(event) => {
								const id = parseInt(event.target.value, 10)
								const company = companies.find((company) => company.id === id)
								setSelectedCompany(company)
							}}
						>
							<option value="" hidden>
								Choose company name
							</option>
							{companies.map(({ id, name }) => (
								<option key={id} value={id}>
									{name}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				)}
				{!currentCustomerId && (
					<>
						<Form.Group
							className="required"
							controlId="new-user-form-user-type"
						>
							<Form.Label>User type</Form.Label>
							<Form.Select
								required
								value={userType ?? ''}
								onChange={(event) => {
									const type = event.target.value
									setUserType(includes(userTypes, type) ? type : undefined)
								}}
							>
								<option value="" hidden>
									Choose user type
								</option>
								<option value="client">Client</option>
								<option value="customer">Customer</option>
								{false && ( //Future Use
									<option value="salesrep">Sales Rep</option>
								)}
								{false && ( //Future Use
									<option value="supplier">Supplier</option>
								)}
							</Form.Select>
						</Form.Group>
						{userType === 'customer' && (
							<>
								<Form.Group
									className="required"
									controlId="new-user-form-customer"
								>
									<Form.Label>Customer</Form.Label>
									<AsyncCustomerTypeahead
										company={selectedCompany}
										onChange={(selected) => {
											setCustomerId(selected.id)
										}}
									/>
								</Form.Group>
							</>
						)}
					</>
				)}
				<Form.Group className="required" controlId="new-user-form-role">
					<Form.Label>Role</Form.Label>
					<Form.Select
						required
						value={selectedRole?.id ?? ''}
						onChange={(event) => {
							const id = parseInt(event.target.value, 10)
							const role = roles.find((role) => role.id === id)
							setSelectedRole(role)
						}}
					>
						<option value="" hidden>
							Choose role
						</option>
						{roles.map(({ id, name }) => (
							<option key={id} value={id}>
								{name}
							</option>
						))}
					</Form.Select>
				</Form.Group>

				<Button variant="primary mt-3" type="submit">
					Save
				</Button>
				<Button
					variant="light"
					onClick={() => {
						onClose?.()
					}}
				>
					Cancel
				</Button>
			</div>
		</Form>
	)
}

export default NewUser
