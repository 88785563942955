// @ts-check

import { useState } from 'react'
import { Button, Alert, Stack, Badge, Dropdown } from 'react-bootstrap'
import { ExclamationCircle } from 'react-bootstrap-icons'
import { Navigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'

const dropdownItemStyle = /** @type {const} */ ({
	whiteSpace: 'normal',
	wordWrap: 'break-word',
	maxWidth: '100%',
})

/** @param {Account | null} chosen */
const getChosenName = (chosen) => {
	if (chosen === null) return 'Select Account'
	return chosen.isClient ? chosen.displayName : chosen.custName
}

/**
 * @param {object} props
 * @param {User} props.user
 */
const CompanyChooser = ({ user }) => {
	const { setCurrentCompany, signout, currentCompany } = useAuth()
	const [chosenAccount, setChosenAccount] = useState(
		/** @type {Account | null} */ (null),
	)
	const [errorMessage, setErrorMessage] = useState(
		/** @type {string | null} */ (null),
	)

	if (currentCompany) {
		return <Navigate to="/" replace />
	}

	return (
		<div className="d-grid gap-4">
			<Alert key={1} variant="primary" className="text-start m-0">
				<Stack direction="horizontal" gap={3}>
					<ExclamationCircle style={{ fontSize: 35 }} />
					Your Email is attached to multiple companies. Please select your
					company name below.
				</Stack>
			</Alert>
			<Dropdown>
				<Dropdown.Toggle className="w-100" variant="outline-secondary">
					{getChosenName(chosenAccount)}
				</Dropdown.Toggle>
				<Dropdown.Menu className="w-100">
					{user.accounts.map((account) => (
						<Dropdown.Item
							onClick={() => setChosenAccount(account)}
							key={account.key}
							style={dropdownItemStyle}
						>
							{account.isClient ? (
								<>
									{account.displayName}
									<Badge bg="mine" style={{ float: 'right' }}>
										{account.roleType}
									</Badge>
								</>
							) : (
								<>
									{account.custName}
									<Badge bg="mine" style={{ float: 'right' }}>
										{account.displayName}
									</Badge>
								</>
							)}
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
			<Button
				size="lg"
				variant={chosenAccount ? 'primary' : 'secondary'}
				disabled={!chosenAccount}
				onClick={() => {
					if (!chosenAccount) {
						return
					}
					setCurrentCompany(chosenAccount, { isPersisted: true })
				}}
			>
				Login
			</Button>
			{errorMessage && (
				<Alert variant="danger" className="m-0">
					{errorMessage}
				</Alert>
			)}
			<Button
				className="link-dark text-decoration-none"
				onClick={(event) => {
					event.preventDefault()
					signout()
					setErrorMessage(null)
				}}
				variant="link"
			>
				Return to Login screen
			</Button>
		</div>
	)
}
export default CompanyChooser
