// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'
import { EpicorBaseService } from './base.service'

/**
 * @extends {EpicorBaseService<
 * 	'quotes',
 * 	Api.Quote,
 * 	unknown,
 * 	unknown,
 * 	Api.QuoteCreationResult
 * >}
 */
class EpicorQuoteService extends EpicorBaseService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		super('quotes', http)
	}
}

export function useEpicorQuoteService() {
	const http = useHttp()
	return useMemo(() => new EpicorQuoteService(http), [http])
}
