import { useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'
import { Table } from 'react-bootstrap'
import { accessors } from './users.table.accessors.component'
import { assertExhaustiveCheck } from 'components/utilities'

/** @import { UserType } from './users.list.component' */

/**
 * @param {object} props
 * @param {UserType} props.userType
 * @param {readonly Api.User[]} props.users
 * @param {() => void} props.onUserDeleted
 * @param {(user: Api.User) => void} props.onEditUser
 * @param {string} props.searchValue
 * @param {string} props.displayType
 */
const UsersTable = ({
	userType,
	users,
	onUserDeleted,
	onEditUser,
	searchValue,
	displayType,
}) => {
	const columns = useMemo(
		() => accessors(userType, displayType),
		[userType, displayType],
	)
	const data = useMemo(
		() =>
			users.filter((user) => {
				let isCorrectUserType
				switch (userType) {
					case 'client':
						isCorrectUserType = user.isClient
						break
					case 'customer':
						isCorrectUserType = !user.isClient
						break
					case 'salesrep':
					case 'supplier':
						isCorrectUserType = false
						break
					default:
						assertExhaustiveCheck(userType)
				}

				const searchText = searchValue.trim().toLowerCase()
				const matchesSearch = searchText
					? searchFields.some((field) =>
							user[field]?.toString().toLowerCase().includes(searchText),
						)
					: true // If searchValue is empty, include all users

				return isCorrectUserType && matchesSearch
			}),
		[userType, users, searchValue],
	)
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data },
		useSortBy,
	)

	return (
		<>
			<Table responsive hover {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => {
						const { key: headerGroupKey, ...headerGroupProps } =
							headerGroup.getHeaderGroupProps()
						return (
							<tr key={headerGroupKey} {...headerGroupProps}>
								{headerGroup.headers.map((column) => {
									const { key: headerKey, ...headerProps } =
										column.getHeaderProps(column.getSortByToggleProps())
									return (
										<th key={headerKey} {...headerProps}>
											{column.render('Header')}
											<span>
												{!column.isSorted
													? ''
													: column.isSortedDesc
														? ' ▼'
														: ' ▲'}
											</span>
										</th>
									)
								})}
							</tr>
						)
					})}
				</thead>
				<tbody>
					{rows.map((row) => {
						prepareRow(row)
						const { key: rowKey, ...rowProps } = row.getRowProps()
						return (
							<tr key={rowKey} {...rowProps}>
								{row.cells.map((cell) => {
									const { key: cellKey, ...cellProps } = cell.getCellProps()
									return (
										<td key={cellKey} {...cellProps}>
											{cell.render('Cell', { onUserDeleted, onEditUser })}
										</td>
									)
								})}
							</tr>
						)
					})}
				</tbody>
			</Table>
		</>
	)
}

const searchFields = /** @type {const}*/ ([
	'firstName',
	'lastName',
	'email',
	'custId',
	'customerName',
])

export default UsersTable
