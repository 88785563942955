import { Button, Dropdown } from 'react-bootstrap'
import { PencilSquare, ThreeDots } from 'react-bootstrap-icons'
import { useUserDataService } from '../../services/user.service'

/** @import { UserType } from './users.list.component' */

/**
 * @param {UserType} userType
 * @param {'client' | 'customer'} displayType
 */
export const accessors = (userType, displayType) => {
	const data = [
		...(displayType === 'client'
			? [
					{
						Header: 'Company',
						accessor: 'companyName',
					},
				]
			: []),
		{
			Header: 'Name',
			accessor: ({ firstName, lastName }) => (
				<div className="d-flex align-items-start">
					<div className="circle d-none d-lg-inline-block me-3">
						{firstName.charAt(0)}
						{lastName.charAt(0)}
					</div>
					<div>
						{firstName} {lastName}
					</div>
				</div>
			),
		},
		{
			Header: 'Email',
			accessor: 'email',
		},
		{
			Header: 'Roles',
			accessor: 'roleName',
		},
		...(userType === 'client' || displayType === 'customer'
			? []
			: [
					{
						Header: 'Customer',
						accessor: (u) => `(${u.custId}) ${u.customerName}`,
					},
				]),
		{
			Header: 'Status',
			accessor: ({ deleted }) =>
				deleted ? (
					<span className="badgeC badgeInActive">Inactive</span>
				) : (
					<span className="badgeC badgeActive">Active</span>
				),
		},
		{
			Header: 'Action',
			accessor: (user) => user,
			Cell: ({ value: user, onUserDeleted, onEditUser }) => (
				<ActionMenu
					user={user}
					onSelectEdit={onEditUser}
					onDeleted={onUserDeleted}
					displayType={displayType}
				/>
			),
		},
	]
	return data
}

function ActionMenu({ user, onSelectEdit, onDeleted, displayType }) {
	const UserDataService = useUserDataService()
	return (
		<Dropdown>
			<Dropdown.Toggle as={Button} variant="light" id="user-action-dropdown">
				<ThreeDots />
			</Dropdown.Toggle>
			<Dropdown.Menu className="shadow">
				{displayType === 'client' && (
					<Dropdown.Item
						eventKey="1"
						as="button"
						onClick={() => {
							onSelectEdit(user)
						}}
					>
						<PencilSquare /> Edit user
					</Dropdown.Item>
				)}
				<Dropdown.Item
					eventKey="2"
					as="button"
					onClick={async () => {
						user.deleted = !user.deleted
						const customerId = user.customerId || undefined
						await UserDataService.update(user.userId, user, customerId)
						onDeleted(user)
					}}
				>
					<em
						className={`bi ${
							user.deleted
								? 'bi-shield text-success'
								: 'bi-shield-slash text-danger'
						}`}
					>
						{user.deleted ? ' Activate user' : ' Deactivate user'}
					</em>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	)
}
