// @ts-check

import { useEffect, useMemo, useRef, useState } from 'react'

export function useIsAutoFilled() {
	const [isAutoFilled, setIsAutoFilled] = useState(false)
	const ref = useRef(/** @type {HTMLInputElement | null} */ (null))

	useEffect(() => {
		const element = ref.current
		if (!element) {
			return
		}

		const changeListener = new AbortController()
		const timeout = setTimeout(() => {
			setIsAutoFilled(element.matches(':autofill'))
			element.addEventListener(
				'change',
				() => {
					setIsAutoFilled(false)
				},
				{ once: true, signal: changeListener.signal },
			)
		}, 100)
		return () => {
			clearTimeout(timeout)
			changeListener.abort()
		}
	}, [])

	return useMemo(() => ({ ref, isAutoFilled }), [isAutoFilled])
}
