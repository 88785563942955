import { Image } from 'react-bootstrap'
import logoWhite from 'logowhite.png' //TODO: Would prefer the SVG file instead.
import logoMain from 'logo.svg'

const Logo = ({ fullSize = false, url = '' }) => {
	const domain = new URL(url || window.location.href).hostname

	const isElevateERP =
		domain.includes('elevateerp.com') || domain === 'localhost'

	const companyLogo = isElevateERP
		? fullSize
			? logoMain
			: logoWhite
		: `https://img.logo.dev/${domain}?token=pk_W7YQpXxwTcWljNk1Dd-Y9g`

	return (
		<Image
			src={companyLogo}
			alt={`${domain} logo`}
			rounded={!isElevateERP}
			className={fullSize || isElevateERP ? 'mx-auto d-block' : 'circle'}
		/>
	)
}

export default Logo
