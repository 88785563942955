import { Col, Container, Row } from 'react-bootstrap'
import { useImpersonation } from 'hooks/useImpersonation'
import PageTitle from './page.title.component'
import UserInfo from './user.info.component'

export const SiteHeader = () => {
	const { impersonatedCustomer } = useImpersonation()

	return (
		<Container fluid className="pt-4 pb-3 border-bottom border-light-subtle">
			<Row className="flex-nowrap">
				<Col>
					<PageTitle />
				</Col>
				<Col xs="auto">{!impersonatedCustomer ? <UserInfo /> : null}</Col>
			</Row>
		</Container>
	)
}
