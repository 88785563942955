import { useCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { downloadFile } from 'components/utilities'
import { useEpicorShipmentService } from 'services/epicor'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import ShipmentsTable from './shipments.table.component'

const ShipmentsList = () => {
	const EpicorShipmentService = useEpicorShipmentService()
	const [shipments, setShipments] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const customerId = useCustomerId()

	const fetchShipments = useCallback(async () => {
		setLoading(true)
		try {
			const response = await EpicorShipmentService.findAll(customerId)
			setShipments(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [EpicorShipmentService, customerId])

	const printShipment = async (packNum) => {
		setError(null)
		try {
			const response = await EpicorShipmentService.print(customerId, packNum)
			downloadFile(response)
		} catch (error) {
			setError(error.message)
		}
	}

	const printBOL = async (packNum, bolNum) => {
		setError(null)
		try {
			const response = await EpicorShipmentService.printBOL(
				customerId,
				packNum,
				bolNum,
			)
			downloadFile(response)
		} catch (error) {
			setError(error.message)
		}
	}

	useEffect(() => {
		fetchShipments()
	}, [fetchShipments])

	return (
		<>
			{error ? <Alert variant="danger">{error}</Alert> : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<ShipmentsTable
					shipments={shipments}
					onPrintShipment={printShipment}
					onPrintBOL={printBOL}
				/>
			)}
		</>
	)
}

export default ShipmentsList
