// @ts-check

import { useMemo, useState, Fragment } from 'react'
import { useSortBy, useTable } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import { getColumnAlignment } from 'components/utilities'
import { accessors } from './invoice.table.accessors.component'
import RecordLines from '../shared/lines.list.component'
import { linesAccessors } from './lines.table.accessors.component'
import TableHead from '../shared/table.header.component'
import _ from 'lodash'

/**
 * @param {object} props
 * @param {readonly Api.Invoice[]} props.invoices
 * @param {(invoiceNum: Id<'invoices'>) => void} props.onPrintInvoice
 * @param {(invoiceNum: Id<'invoices'>) => void} props.onDownloadAttachments
 * @param {(invoice: Api.Invoice) => void} props.onPayInvoice
 */
const InvoicesTable = ({
	invoices,
	onPrintInvoice,
	onDownloadAttachments,
	onPayInvoice,
}) => {
	const [expandedRow, setExpandedRow] = useState(
		/** @type {Id<'invoices'> | null} */ (null),
	)
	const columns = useMemo(() => accessors(), [])
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data: invoices },
		useSortBy,
	)

	const TableBody = () => {
		/**
		 * @param {Id<'invoices'>} invoiceNum
		 * @param {import('react-table').Cell} cell
		 */
		const toggleCollapse = (invoiceNum, cell) => () => {
			if (cell.column.id === '⚡') return
			setExpandedRow((prevState) =>
				invoiceNum === prevState ? null : invoiceNum,
			)
		}

		return (
			<tbody>
				{rows.map((row) => {
					prepareRow(row)
					const { key: rowKey, ...rowProps } = row.getRowProps()
					const isExpanded = expandedRow === row.original.invoiceNum

					return (
						<Fragment key={rowKey}>
							<tr
								className={isExpanded ? 'table-active' : undefined}
								{...rowProps}
							>
								{row.cells.map((cell) => {
									const { key: cellKey, ...cellProps } = cell.getCellProps()
									return (
										<td
											key={cellKey}
											{...cellProps}
											onClick={toggleCollapse(row.original.invoiceNum, cell)}
											className={getColumnAlignment(cell.column.id)}
										>
											{cell.render('Cell', {
												onPrintInvoice,
												onDownloadAttachments,
												onPayInvoice,
											})}
										</td>
									)
								})}
							</tr>
							{isExpanded && (
								<tr className="table-active">
									<td colSpan={row.cells.length}>
										<RecordLines
											record={row.original.invoiceNum}
											type="invoice"
											linesAccessors={linesAccessors}
										/>
									</td>
								</tr>
							)}
						</Fragment>
					)
				})}
			</tbody>
		)
	}

	return !_.isEmpty(invoices) ? (
		<BTable responsive hover {...getTableProps()}>
			<TableHead headerGroups={headerGroups} />
			<TableBody />
		</BTable>
	) : (
		<p>The current customer does not have any invoices.</p>
	)
}

export default InvoicesTable
