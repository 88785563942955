// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'
import { EpicorBaseService } from './base.service'

/**
 * @extends {EpicorBaseService<
 * 	'orders',
 * 	Api.Order,
 * 	unknown,
 * 	unknown,
 * 	Api.OrderCreationResult
 * >}
 */
class EpicorOrderService extends EpicorBaseService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		super('orders', http)
	}
}

export function useEpicorOrderService() {
	const http = useHttp()
	return useMemo(() => new EpicorOrderService(http), [http])
}
