import { Dropdown, Badge } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'
import { formatCurrency, formatDate } from '../utilities'

export const accessors = () => [
	{
		Header: 'Quotes List',
		columns: [
			{
				Header: 'Quote#',
				accessor: 'quoteNum',
			},
			{
				Header: 'Status',
				accessor: (item) =>
					item.quoteClosed ? (
						<Badge bg="secondary">Closed</Badge>
					) : item.quoted ? (
						<Badge bg="success">Quoted</Badge>
					) : (
						<Badge bg="warning">Pending</Badge>
					),
			},
			{
				Header: 'PO#',
				accessor: 'pONum',
			},
			{
				Header: 'Ship Via',
				accessor: 'shipViaCode',
			},
			{
				Header: 'Quote Date',
				accessor: (item) =>
					item.entryDate != null ? formatDate(item.entryDate) : '',
			},
			{
				Header: 'Due Date',
				accessor: (item) =>
					item.needByDate != null ? formatDate(item.needByDate) : '',
			},
			{
				Header: 'Quote Amount',
				accessor: (item) =>
					formatCurrency(item.docTotalQuote, item.currencyCurrencyID),
			},
			{
				Header: '⚡',
				accessor: (quote) => quote,
				Cell: ({ value: quote, onPrintQuote, onDownloadAttachments }) =>
					quote.quoted && (
						<Dropdown>
							<Dropdown.Toggle
								as={CustomToggle}
								id="dropdown-custom-components"
							/>
							<Dropdown.Menu as={CustomMenu}>
								<Dropdown.Item
									eventKey="1"
									onClick={() => {
										onPrintQuote(quote.quoteNum)
									}}
								>
									Download Quote
								</Dropdown.Item>
								{quote.hasAttachments && (
									<Dropdown.Item
										eventKey="2"
										onClick={() => {
											onDownloadAttachments(quote.quoteNum)
										}}
									>
										Download Attachments
									</Dropdown.Item>
								)}
							</Dropdown.Menu>
						</Dropdown>
					),
			},
		],
	},
]
