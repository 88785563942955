// @ts-check

import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { assertExhaustiveCheck, getErrorMessage } from 'components/utilities'
import { usePossibleCustomerId } from 'hooks/useAuth'
import { useUserDataService } from '../../services/user.service'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import UsersTable from './users.table.component'

/**
 * @param {object} props
 * @param {import('hooks/useRefresher').Refresher} props.refresher
 * @param {UserType} props.userType
 * @param {(user: Api.User) => void} props.onEditUser
 * @param {string} props.searchValue
 * @param {string} props.displayType
 */
const UsersList = ({
	refresher,
	userType,
	onEditUser,
	searchValue,
	displayType,
}) => {
	const [users, setUsers] = useState(/** @type {readonly Api.User[]} */ ([]))
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(/** @type {string | null} */ (null))

	const customerId = usePossibleCustomerId()
	const UserDataService = useUserDataService()

	const fetchUsers = useCallback(async () => {
		if (!UserDataService || (userType === 'customer' && !customerId)) {
			return
		}
		setLoading(true)
		try {
			let response
			switch (userType) {
				case 'client':
					response = await UserDataService.getAll()
					break
				case 'customer':
					if (!customerId) {
						throw new Error('Customer ID is required for customer user type.')
					}
					response = await UserDataService.getAllForCustomer(customerId)
					break
				case 'salesrep':
				case 'supplier':
					throw new Error(`Unimplemented for user type "${userType}".`)
				default:
					assertExhaustiveCheck(userType)
			}
			setUsers(response.data)
		} catch (error) {
			setError(getErrorMessage(error))
		} finally {
			setLoading(false)
		}
	}, [UserDataService, userType, customerId])

	useEffect(() => {
		fetchUsers()
	}, [fetchUsers, refresher.id])

	return (
		<>
			{error ? <Alert variant="danger">{error}</Alert> : null}
			{loading ? (
				<LoadingParagraphs cant={7} />
			) : (
				<UsersTable
					userType={userType}
					users={users}
					onUserDeleted={() => {
						refresher.refresh()
					}}
					onEditUser={onEditUser}
					searchValue={searchValue}
					displayType={displayType}
				/>
			)}
		</>
	)
}

export const userTypes = /** @type {const} */ ([
	'client',
	'customer',
	'salesrep',
	'supplier',
])

/** @typedef {typeof userTypes[number]} UserType */

export default UsersList
